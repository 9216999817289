.search_table_form {
    margin-left: 32px;
    width: 340px;
    float: left;
}
.search_table_form .autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
}
.autocomplete-items {
    position: absolute;
    z-index: 99;    
    top: 48px;
    left: 0;
    right: 0;    
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--new-whisper);
    max-height: 340px;
    overflow: hidden;
    overflow-y: auto;
  }
  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: var(--white-fill);
    border-bottom: 1px solid var(--light-grey);
    
    font-size: 14px;
    font-weight: normal;
    
    
    line-height: 2.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    padding: 2px 10px;
  }
  .autocomplete-items div:first-child {
    border-radius: 6px 6px 0 0;
  }
  .autocomplete-items div:last-child {
    border-bottom: 0;
    border-radius: 0 0 6px 6px;
  }
  .autocomplete-items div:hover {    
    background-color: var(--whisper);
  }
  /* .autocomplete-active {  
    background-color: DodgerBlue !important;
    color: var(--white-fill);   
  } */
  .card-main-header #search_table,
   .search_table_input  {   
    width: 100%;
    height: 40px;
    padding: 0 12px 0 44px;
    border-radius: 6px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;    
    font-size: 14px;
    font-weight: normal;    
    line-height: 40px;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    background-image: url('../../../assets/images/search.svg');
    background-position: left 14px center;
    background-repeat: no-repeat;
    background-size: 17px 17px;
    margin-left: 0px !important;
}

.card-main-header #search_tablet::-webkit-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.52);
}
.card-main-header #search_tablet::-moz-placeholder { 
    color: rgba(var(--charcoal-grey-rgb), 0.52);
}
.card-main-header #search_tablet:-ms-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.52);
}
.card-main-header #search_tablet:-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.52);
}
.card-main-header #search_table:focus-visible {
    outline: 0;
}
.type-include .autocomplete-items {
    border-radius: 12px;
    -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
    box-shadow: 0 6px 12px 0 var(--black-shadow1);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);
    max-height: 340px;
    overflow: hidden;
    overflow-y: auto;
}
.type-include .autocomplete-items div,
.type-include .autocomplete-items div.no-result {
    border-bottom: 0 !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
}
.type-include .autocomplete-items div:hover {
    background-color: transparent !important;
}
.type-include .autocomplete-items div.search-item-wrapper:first-child {
    border-radius: 12px 12px 0 0 !important;
}
.type-include .autocomplete-items div.search-item-wrapper:last-child {
    border-radius: 0 0 12px 12px !important;
}
.type-include .autocomplete-items div.search-item-wrapper {
    border-bottom: 1px solid var(--approx-whisper) !important;
    background-color: var(--white-fill) !important;
    padding: 14px 12px 12px 12px !important;
}
.type-include .autocomplete-items div.search-item-wrapper:last-child {
    border-bottom: 0 !important;
}
.autocomplete-active,
.type-include .autocomplete-items div.search-item-wrapper:hover,
.type-include .autocomplete-items div.search-item-wrapper.autocomplete-active,
.type-include .autocomplete-items div.search-item-wrapper.autocomplete-active:hover  {
    background-color: var(--whisper) !important;
}
/* .type-include .autocomplete-items div.search-item-wrapper.autocomplete-active:hover {
    background-color: DodgerBlue !important;
    color: var(--white-fill);
} */
.type-include .autocomplete-items div.search-item-wrapper div,
.type-include .autocomplete-items div.search-item-wrapper div span {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 2.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);  
}
.type-include .autocomplete-items div.search-item-wrapper div.search-item,
.type-include .autocomplete-items div.search-item-wrapper div.search-item span {
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: -0.11px;
    color: var(--charcoal-grey);
}
.type-include .autocomplete-items div.search-item-wrapper div.search-item span {
    background-color: var(--blanched-almond);
    font-size: 14px !important;
}
.type-include .autocomplete-items div.search-item-wrapper div.item-type,
.type-include .autocomplete-items div.search-item-wrapper div.item-type span {  
    font-size: 12px !important;  
    line-height: normal !important;
    letter-spacing: 0.24px !important;
    color: var(--battleship-grey) !important;
}
.type-include .autocomplete-items div.search-item-wrapper div.item-type {
    margin-top: 8px;
}
.type-include .autocomplete-items div.search-item-wrapper div.item-type span {
    background-color: transparent !important;
}
.type-include .autocomplete .field-container {
    position: relative;
}
.type-include .autocomplete-items div.no-result {
    font-size: 14px;
    line-height: 2.71;
    letter-spacing: normal;
    font-style: italic !important;
    font-weight: 500 !important;
    border-bottom: 0 !important;
    cursor: default !important; 
    color: var(--lynch) !important;
    border-radius: 12px !important;
    background-color: var(--white-fill) !important;
    padding: 2px 10px !important;
    pointer-events: none;
}
.type-include .autocomplete-items div.search-item-wrapper.no-result:last-child,
.type-include .autocomplete-items div.search-item-wrapper.no-result:first-child{
    border-radius: 12px !important;
}
.type-include .autocomplete-items div.search-item-wrapper div.search-item img {
    width: 16px;
    height: auto;
    position: relative;
    top: -2px;
    margin-right: 6px;
}
/* .type-include .autocomplete-items div.search-item-wrapper.autocomplete-active {
    background-color: DodgerBlue !important;
    color: var(--white-fill);    
}
.type-include .autocomplete-items div.search-item-wrapper.autocomplete-active * {
    color: var(--white-fill) !important;
}
.type-include .autocomplete-items div.search-item-wrapper.autocomplete-active span {
    background-color: transparent !important;
}   */
.clear-search {
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: var(--slate);
    right: 14px;
    top: 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border: none;  
    border-radius: 50%;
    cursor: pointer;
    background: url("../../../assets/images/close-icon-grey-bg.svg") no-repeat center;
    background-size: 13px 13px;
}
.result-count {
    position: absolute;
    min-width: 99px;
    height: 17px;  
    right: 36px;
    top: 11px;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
}
@media (min-width: 768px) and (max-width: 991px) {
    .main-card .search_table_form {
        clear: both;
        width: 100% !important;
        max-width: none !important;
        margin-top: 16px !important;
    }
}
@media (min-width: 992px) and (max-width: 1024px) {
    .main-card .search_table_form {
        margin-left: 0;
        width: 54% !important;
        max-width: none !important;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .main-card .search_table_form {
        margin-left: 0;
    }
}
@media (max-width: 1199px) {
    .search_table_form {    
        width: 33% !important;
        max-width: 340px !important;
    }
}  