/* .tcs-fields-wrapper {
  width: 100%;
}
.tcs-dropdown {
  width: calc(100% - 110px) !important;
}
.tcs-entry {
  width: 110px !important;
}
.tcs-entry input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
}
.tcs-select.total-section-tcs div[id$="-listbox"] {
  top: auto !important;
  bottom: 46px !important;
}
.tcs-dropdown .taxcode-dropdown .search-dropdown {
  border-left: solid 1px var(--new-whisper) !important;
  border-radius: 6px 0 0 6px !important;
}
.tcs-dropdown .taxcode-dropdown .search-dropdown:focus {
  border-left: 1px solid var(--cerulean-blue) !important;
}
.select-tcs-dropdown div.error {
  border-radius: 6px !important;
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
  border: solid 1px var(--red) !important;
}
.error .tcs-dropdown input,
.error .tcs-entry input {
  background-color: var(--rose-white) !important;
}
.tcs-fields-wrapper.error {
  border: solid 1px var(--red) !important;
  border-radius: 6px;
}
.tcs-fields-wrapper.tcs-fields-full-width .tcs-dropdown {
  width: 100% !important;
} */
.tcs-select-wrapper {
  width: 100%;
  border-radius: 6px !important;
  border: 1px solid var(--new-whisper) !important;
  align-items: center;
}
.tcs-select-wrapper.error {
  border: solid 1px var(--red) !important;
  border-radius: 6px;
}
.error .tcs-dropdown-wrapper input,
.error .tcs-entry-wrapper input {
  background-color: var(--rose-white) !important;
}
.tcs-select-wrapper input {
  border: none !important;
}
/* .tcs-select-wrapper input:focus {
  border: none !important;
} */
.tcs-dropdown-wrapper {
  width: 100% !important;
  height: 100% !important;
  flex: 4;
  border-right: 1px solid var(--new-whisper) !important;
  border-radius: 6px 0px 0px 6px !important;
}
.tcs-input-dropdown-wrap {
  height: 40px !important;
}
.tcs-select-wrapper .tcs-entry-wrapper {
  width: 100% !important;
  flex: 2;
}
.tcs-select-wrapper .tcs-entry-wrapper input {
  height: 100% !important;
}
/* .tcs-select-wrapper .tcs-dropdown:focus {
  border-radius: 6px 0px 0px 6px !important;
  border: 1px solid var(--cerulean-blue) !important;
} */
.tcs-select-wrapper .tcs-entry-wrapper:focus {
  border-radius: 0px 6px 6px 0px !important;
  border: 1px solid var(--cerulean-blue) !important;
}
.tcs-select-wrapper .tcs-entry-wrapper input {
  border-radius: 0px 6px 6px 0px !important;
}
.tcs-select-wrapper .tcs-dropdown-wrapper input {
  height: 100% !important;
  border-radius: 6px 0px 0px 6px !important;
  padding: 0px 10px;
}
.tcs-dropdown-total .search-dropdown.chosen {
  border-radius: 6px !important;
}
.tcs-dropdown-total .search-dropdown:focus {
  border-left: 1px solid var(--cerulean-blue) !important;
}
.tcs-dropdown-total .dropdown-menu {
  bottom: 0 !important;
  transform: translate(0, -44px) !important;
}

.tcs-converted-amount {
  color: var(--battleship-grey);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
}
