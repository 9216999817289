/* .tds-fields-wrapper {
  width: 100%;
}
.tds-dropdown {
  width: calc(100% - 110px) !important;
}
.tds-entry {
  width: 110px !important;
}
.tds-entry input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
}
.tds-select.total-section-tds div[id$="-listbox"] {
  top: auto !important;
  bottom: 46px !important;
}
.tds-dropdown .taxcode-dropdown .search-dropdown {
  border-left: solid 1px var(--new-whisper) !important;
  border-radius: 6px 0 0 6px !important;
}
.tds-dropdown .taxcode-dropdown .search-dropdown:focus {
  border-left: 1px solid var(--cerulean-blue) !important;
}
.select-tds-dropdown div.error {
  border-radius: 6px !important;
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
  border: solid 1px var(--red) !important;
}
.error .tds-dropdown input,
.error .tds-entry input {
  background-color: var(--rose-white) !important;
}
.tds-fields-wrapper.error {
  border: solid 1px var(--red) !important;
  border-radius: 6px;
}
.tds-fields-wrapper.tds-fields-full-width .tds-dropdown {
  width: 100% !important;
} */
.tds-select-wrapper {
  width: 100%;
  border-radius: 6px !important;
  border: 1px solid var(--new-whisper) !important;
  align-items: center;
}
.tds-select-wrapper.error {
  border: solid 1px var(--red) !important;
  border-radius: 6px;
}
.error .tds-dropdown-wrapper input,
.error .tds-entry-wrapper input {
  background-color: var(--rose-white) !important;
}
.tds-select-wrapper input {
  border: none !important;
}
/* .tds-select-wrapper input:focus {
  border: none !important;
} */
.tds-dropdown-wrapper {
  width: 100% !important;
  height: 100% !important;
  flex: 4;
  border-right: 1px solid var(--new-whisper) !important;
  border-radius: 6px 0px 0px 6px !important;
}
.tds-input-dropdown-wrap {
  height: 40px !important;
}
.tds-select-wrapper .tds-entry-wrapper {
  width: 100% !important;
  flex: 2;
}
.tds-select-wrapper .tds-entry-wrapper input {
  height: 100% !important;
}
/* .tds-select-wrapper .tds-dropdown:focus {
  border-radius: 6px 0px 0px 6px !important;
  border: 1px solid var(--cerulean-blue) !important;
} */
.tds-select-wrapper .tds-entry-wrapper:focus {
  border-radius: 0px 6px 6px 0px !important;
  border: 1px solid var(--cerulean-blue) !important;
}
.tds-select-wrapper .tds-entry-wrapper input {
  border-radius: 0px 6px 6px 0px !important;
}
.tds-select-wrapper .tds-dropdown-wrapper input {
  height: 100% !important;
  border-radius: 6px 0px 0px 6px !important;
  padding: 0px 10px;
}
.tds-dropdown-total .search-dropdown.chosen {
  border-radius: 6px !important;
}
.tds-dropdown-total .search-dropdown:focus {
  border-left: 1px solid var(--cerulean-blue) !important;
}
.tds-dropdown-total .dropdown-menu {
  bottom: 0 !important;
  transform: translate(0, -44px) !important;
}

.tds-converted-amount {
  color: var(--battleship-grey);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
}
