.close-preview-button {
  font-size: 15px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: var(--booger);
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--booger);
  background-color: var(--white-fill);
  float: right;
  padding: 14px 22px;
}

.close-preview-button:hover {
  color: var(--white-fill);
  background-color: var(--booger);
}

.header-wrap h1 {
  max-width: calc(100% - 234px);
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 15px;
  float: left;
}

#invoice {
  width: 100%;
  display: inline-block;
  padding: 40px;
  border-radius: 12px;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
}

#invoice .invoice-header {
  border-radius: 6px;
  background-color: var(--white-smoke);
  padding: 30px;
  margin-bottom: 40px;
}

#invoice .company-logo {
  height: 61px;
  width: 100px;
  background-color: black;
  margin-bottom: 18px;
}

#invoice .company-address,
#invoice .company-address span {
  font-size: 15px;
  font-weight: 500;

  line-height: 1.4;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}

#invoice .billed-to .company-address span,
#invoice .ship-to .company-address span {
  line-height: 1.47;
}
#invoice .billed-to .company-address {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-top: 11px;
}
#invoice .company-address span.company-name {
  display: inline-block;
  width: 100%;

  font-weight: bold;
  font-size: 19px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 8px;
}

.label-invoice {
  font-size: 36px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: -0.2px;
  text-align: right;
  color: var(--charcoal-grey);
  margin-bottom: 16px;
}

.invoice-number-label {
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--battleship-grey);
  margin-bottom: 6px;
}

.invoice-number {
  font-size: 20px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  /* color: var(--charcoal-grey); */
  color: var(--charcoal-grey);
  margin-bottom: 16px;
}

.bill-details-section {
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.bill-details-section .bill-details-header {
  font-size: 18px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.bill-details-section .billed-to .bill-details-header,
.bill-details-section .ship-to .bill-details-header {
  font-weight: bold;
}
.bill-details-section .inv-date .bill-details-header,
.bill-details-section .payment-terms .bill-details-header {
  font-weight: 600;
}

.bill-details-header .company-address {
  line-height: 1.5;
}

.inv-date-wrap .inv-date div,
.payment-terms div {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  padding-top: 11px;
}

.inv-date-wrap .inv-date:last-child {
  margin-top: 21px;
}

#invoice.invoice-preview .billed-to,
#invoice.invoice-preview .ship-to {
  width: 284px !important;
  flex: 0 0 284px !important;
}

#invoice .table-section {
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
  padding-bottom: 30px;
}

#invoice .table-section .table thead {
  border-top: none;
}

#invoice .table-section .table thead tr {
  height: 48px;
}

#invoice .table-section .table thead th {
  text-align: end;
  border-top: 0;

  font-size: 12px;
  font-weight: bold;

  line-height: 2;
  /* color: var(--battleship-grey) !important; */
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
  border-bottom: 1px solid var(--approx-whisper) !important;
}
#invoice .table-section .table thead th:last-child {
  text-align: right;
}
#invoice .table-section .table.inv-preview-table thead th:nth-child(3),
#invoice .table-section .table.inv-preview-table tr td:nth-child(3) {
  text-align: left !important;
  width: 13%;
}
#invoice .table-section .table.inv-preview-table thead th:nth-child(6),
#invoice .table-section .table.inv-preview-table tr td:nth-child(6) {
  padding-right: 10px !important;
}
#invoice .table-section .table.inv-preview-table thead th:nth-child(7),
#invoice .table-section .table.inv-preview-table tr td:nth-child(7) {
  text-align: left !important;
  padding-left: 10px !important;
}
#invoice .table-section .table tbody th {
  font-size: 14px;
  font-weight: bold;

  line-height: 0.71;
  letter-spacing: normal;
  /* color: var(--charcoal-grey); !important; */
  color: var(--charcoal-grey) !important;
  border-bottom: 1px solid var(--approx-whisper) !important;
}

#invoice .table-section .table tbody td {
  border-bottom: 1px solid var(--approx-whisper) !important;
}

#invoice .table-section .table th:last-child,
#invoice .table-section .table td:last-child {
  padding-right: 24px !important;
}

#invoice .table-section .table th:first-child {
  text-align: start;
  padding-left: 24px !important;
  width: 20%;
}

#invoice .table-section .table td {
  text-align: end;
}

.thankyou-note {
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-left: 32px;
}

#invoice .thankyou-note {
  font-size: 18px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-left: 24px;
}

.right-content {
  max-width: 600px;
}

.calc-section,
.total-calc {
  border-bottom: 1px solid var(--approx-whisper);
  margin-bottom: 20px;
}

.total-calc:last-child {
  border-bottom: 0;
}

.calc-section > div,
.total-calc > div {
  display: flex;
  justify-content: space-between;
}

.subtotal-section,
.subtotal-section > div {
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.2px;

  text-align: right;
  color: var(--charcoal-grey);
}

.discount-section,
.tax-section,
.discount-section > div,
.tax-section > div {
  font-size: 16px;
  font-weight: 500;
  /* color: var(--battleship-grey); */

  line-height: normal;
  letter-spacing: -0.2px;
  color: var(--battleship-grey);
}
.discount-section > div:first-child {
  padding-right: 16px;
}
.tax-section,
.tax-section > div {
  font-size: 14px;
  letter-spacing: -0.18px;
}
.tax-section div:first-child {
  width: 55%;
  padding-left: 25px;
}

.discount-section div:last-child,
.tax-section div:last-child {
  color: var(--charcoal-grey);
}

.total-calc {
  font-size: 18px;
}

.total-calc > div div:first-child {
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--battleship-grey);
}

.total-calc > div div:last-child {
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
}
.total-calc > .total-section > div:first-child {
  font-size: 18px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: -0.23px;
}
.total-calc > .total-section > div:last-child {
  font-size: 18px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
}
.total-calc > .total-section > .total-in-words-label {
  flex-shrink: 0;
  margin-right: 20px;
}
.total-calc > .total-section > .total-in-words {
  max-width: 300px;
  flex: 1;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal !important;
  font-weight: 500 !important;
}

.payment-made-section div {
  font-size: 18px;
  font-weight: bold !important;

  line-height: normal !important;
  letter-spacing: -0.23px !important;
}
.payment-made-section div:first-child {
  color: var(--battleship-grey) !important;
}

.payment-made-section div:last-child {
  text-align: right;
  color: var(--crusoe) !important;
}

#invoice .table-section thead th:nth-child(2),
#invoice .table-section tbody td:nth-child(2),
#invoice .table-section thead th:nth-child(6),
#invoice .table-section tbody td:nth-child(6) {
  text-align: left;
}

#invoice .table-section tbody td,
#invoice .table-section tbody td span.amt,
#invoice .table-section tbody td span.acnt {
  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
#invoice .table-section tbody td span.amt,
#invoice .table-section tbody td span.acnt {
  display: block;
  width: 100%;
  clear: both;
}
#invoice .table-section tbody td span.amt {
  line-height: normal;
  padding: 0 0 4px 0;
}
#invoice .table-section tbody td span.actual-value,
#invoice .table-section tbody td span.current-value {
  font-size: 13px;
  line-height: normal;
  padding: 4px 0;
}
#invoice .table-section tbody td span.acnt {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  text-align: right;
}
#invoice .table-section .table td:last-child {
  font-size: 14px;
  font-weight: bold;

  line-height: 0.71;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
}
.amt-calculation-portion {
  padding-right: 24px !important;
}
.bill-details-section .inv-date-wrap {
  width: 23%;
  min-width: 100px;
  flex: 0 0 23%;
}
.bill-details-section .payment-terms {
  width: 153px;
}

#invoice table tbody th {
  padding-top: 24px !important;
}
#invoice table tbody td {
  padding-top: 24px !important;
}
#invoice .tax-groups > div {
  margin-bottom: 12px;
}
#invoice .tax-groups > div:last-child {
  margin-bottom: 0;
}

/* ----------------------------------------------------------------*/
#invoice.payment-recieved-preview {
}
#invoice.payment-recieved-preview .pr-preview-header .company-address span {
  display: block;
  width: 200px;

  font-size: 14px;
  font-weight: 500;

  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  clear: both;
}
#invoice.payment-recieved-preview
  .pr-preview-header
  .company-address
  span.company-name {
  font-size: 19px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
#invoice.payment-recieved-preview .pr-preview-header .bill-addr-label {
  width: auto;
  text-align: right;

  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  clear: both;
  margin-bottom: 8px;
}
.bill-addr-label {
  width: auto;

  font-size: 14px;
  font-weight: bold;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  clear: both;
  margin-bottom: 8px;
}
#invoice.payment-recieved-preview .pr-preview-header .bill-address,
#invoice.payment-recieved-preview .pr-preview-header .bill-address span {
  display: block;
  width: 262px;
  float: right;

  font-size: 14px;
  font-weight: 500;

  line-height: 1.57;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
  clear: both;
  margin-right: 0 !important;
}
#invoice .table-section.payment-detail-section {
  padding-top: 18px;
  border: 0;
  border-radius: 0;
  background-color: var(--white-fill);
  padding-bottom: 8px;
}
.payment-detail-section .pay-detail-row .col {
  margin-right: 7.6%;
  padding-bottom: 20px;
}
.payment-detail-section .pay-detail-row .col:last-child {
  margin-right: 0;
}
.payment-detail-section .pay-date-col {
  width: 120px;
  flex: 0 0 120px;
}
.payment-detail-section .pay-item-col {
  width: 148px;
  flex: 0 0 148px;
}
.payment-detail-section .pay-mode-col {
  width: 150px;
  flex: 0 0 150px;
}

.payment-detail-section .pay-item-text {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  margin-bottom: 8px;
}
.payment-detail-section .pay-item-value {
  font-size: 16px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-transform: capitalize;
}
.payment-detail-section .pay-words-col {
  max-width: 400px;
}
.amout-received-digits {
  flex: 0 0 auto;
  min-width: 400px;
  max-width: 100%;
  border-radius: 6px;
  border: solid 1px var(--battleship-grey);
  margin-top: 20px;
  float: left;
}
.amout-received-digits span {
  display: block;
  line-height: normal;
  letter-spacing: normal;
}
.amout-received-digits .element-label {
  width: 188px;
  font-size: 18px;
  font-weight: 600;
  color: var(--battleship-grey);
  padding: 37px 20px;
}
.amount {
  width: calc(100% - 188px);
  font-size: 36px;
  font-weight: 900;
  text-align: right;
  color: var(--charcoal-grey);
  padding: 23px 30px 0 0;
}
.amout-received-digits .amount {
  white-space: nowrap;
}
.signatury-wrapper {
  width: 200px;
  padding-top: 40px;
}
.signatury-wrapper .signature-label {
  display: block;
  width: 100%;

  font-size: 14px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  clear: both;
  float: left;
}
.signatury-wrapper .signature {
  width: 100%;
  height: 37px;
  border: 0;
  border-bottom: solid 1px var(--slate);
  border-radius: 0;
  float: left;
}

.flex-no-shrink {
  flex-shrink: 0;
}
.show-excess-amt {
  display: flex;
  align-items: center;
  padding-left: 28px;
}

.show-excess-amt span {
  font-size: 18px;

  line-height: normal;
  letter-spacing: normal;
}
.show-excess-amt .excess-label {
  font-weight: 600;
  color: var(--battleship-grey);
}
.show-excess-amt .excess-amt {
  font-weight: 900;
  color: var(--charcoal-grey);
  padding-left: 8px;
}
.pay-item-details {
  margin-top: 40px;
}
.payment-detail-table-wrapper {
  border-radius: 4px;
  border: solid 1px var(--approx-whisper);
  margin-top: 20px;
  overflow: hidden;
  overflow-x: auto;
}
.payment-detail-table {
  border-top: 1px solid var(--approx-whisper);
  min-width: 700px;
}
.payment-detail-table th,
.payment-detail-table td {
  text-align: left !important;
  white-space: nowrap !important;
}
.payment-detail-table tr th:last-child,
.payment-detail-table tr td:last-child {
  text-align: right !important;
}
.payment-detail-table tr td:first-child {
  font-weight: bold !important;
}
#invoice .payment-detail-table tbody tr:last-child td {
  border-bottom: 0 !important;
}
.payment-detail-table td {
  padding: 20px 24px !important;
}
.payment-detail-table tr td:last-child {
  font-weight: 600 !important;
}
.pay-table-text {
  width: 100%;
  display: flex;
}
.pay-table-text span {
  font-size: 14px;
  font-weight: 600;

  line-height: normal;
  letter-spacing: normal;
  color: var(--battleship-grey);
  padding: 14px 24px;
}

.cd-detail-card .table-section .payment-detail-table th,
.cd-detail-card .table-section .payment-detail-table td {
  width: 25% !important;
}
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table
  thead
  th,
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table
  tbody
  td {
  width: 25% !important;
}

#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table.with-withhold
  thead
  th,
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table.with-withhold
  tbody
  td {
  width: 21% !important;
}
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table.with-withhold
  thead
  th:nth-child(4),
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table.with-withhold
  tbody
  td:nth-child(4) {
  width: 18% !important;
}
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table.with-withhold
  thead
  th:last-child,
#invoice.pr-preview
  .table-section
  .payment-detail-table.pr-detail-table.with-withhold
  tbody
  td:last-child {
  width: 18% !important;
}

.total-calc > .total-section > div:last-child,
.total-calc > div div:last-child,
.discount-section div:last-child,
.tax-section div:last-child,
.subtotal-section > div:last-child,
#invoice .table-section table.cnote-preview-table tbody td:last-child {
  white-space: nowrap;
}

.invoice-payment-made {
  width: 129px;
  height: 22px;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--deep-green);
}

/** Media Queries **/

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (max-width: 991px) {
  .amout-received-digits {
    align-items: center;
    min-height: 98px;
    padding: 23px 0;
  }
  .amout-received-digits .element-label {
    width: 165px;
    font-size: 16px;
    padding: 0 16px;
  }
  .amount {
    width: calc(100% - 165px);
    padding: 0;
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .invoice-actions-wrapper .left-group {
    max-width: 220px;
  }
  #invoice.invoice-preview .billed-to,
  #invoice.invoice-preview .ship-to {
    flex: 0 0 195px !important;
  }
  .bill-details-section .inv-date-wrap,
  .bill-details-section .payment-terms {
    width: 195px !important;
  }

  #invoice.invoice-preview .invoice-header,
  #invoice.cn-preview .invoice-header {
    flex-direction: column;
  }
  #invoice.invoice-preview .invoice-details,
  #invoice.cn-preview .invoice-details {
    margin-top: 20px;
  }
  #invoice.invoice-preview .invoice-details .label-invoice,
  #invoice.invoice-preview .invoice-details .invoice-number-label,
  #invoice.invoice-preview .invoice-details .invoice-number,
  #invoice.cn-preview .invoice-details .label-invoice,
  #invoice.cn-preview .invoice-details .invoice-number-label,
  #invoice.cn-preview .invoice-details .invoice-number {
    text-align: left;
  }
  .bill-addr-label,
  .bill-address {
    text-align: left;
  }
  .table-section.payment-detail-section .pay-detail-row {
    flex-direction: column;
  }
  .payment-detail-section .pay-detail-row .col {
    margin-right: 0 !important;
    padding-bottom: 15px;
    width: auto;
    flex: 0 0 auto;
  }
}
@media (max-width: 1150px) {
  #invoice.invoice-preview .bill-details-section .payment-terms {
    width: 284px;
  }
}
@media (min-width: 1025px) and (max-width: 1150px) {
  #invoice.invoice-preview .billed-to,
  #invoice.invoice-preview .ship-to {
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
  .invoice-actions-wrapper .left-group {
    max-width: 410px;
  }
  #invoice.invoice-preview .billed-to,
  #invoice.invoice-preview .ship-to {
    flex: 0 0 284px !important;
  }
  .bill-details-section .inv-date-wrap,
  #invoice.invoice-preview .bill-details-section .payment-terms {
    width: 284px !important;
  }
  .payment-detail-section .pay-detail-row .col:last-child {
    width: auto;
    flex: 0 0 auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .invoice-actions-wrapper .left-group {
    flex-direction: column;
  }
  /* .detail-pg-actions-wrapper .actions-wrap a {
    margin-bottom: 12px;    
  }
  .detail-pg-actions-wrapper .actions-wrap a:last-child{
    margin-bottom: 0;
  } */
  .invoice-actions-wrapper .left-group > span:nth-child(even) {
    display: none;
  }
  .invoice-actions-wrapper .left-group > span {
    margin-bottom: 12px;
    padding-left: 0 !important;
  }
  .invoice-actions-wrapper .left-group > span:last-child {
    margin-bottom: 0;
  }
  .invoice-actions-wrapper .left-group > span > .org-tag {
    margin-left: 0 !important;
  }
  .bill-details-section {
    flex-wrap: wrap;
  }
  /* .bill-details-section .inv-date-wrap,
  .bill-details-section .payment-terms {    
    margin-top: 20px;
  } */
  #invoice.invoice-preview .ship-to,
  #invoice.invoice-preview .bill-details-section .payment-terms {
    text-align: right;
  }
  .table-bottom-sect-wrap {
    flex-direction: column;
  }
  .table-bottom-sect-wrap .amt-calculation-portion {
    width: 100% !important;
    max-width: none !important;
    padding-left: 24px;
  }
  .table-bottom-sect-wrap .calc-section,
  .table-bottom-sect-wrap .total-calc {
    width: 100% !important;
    margin-left: auto;
  }
  #invoice .thankyou-note {
    order: 2;
    margin-left: 24px;
    margin-top: 40px;
  }
  .total-calc > .total-section > div:first-child,
  .total-calc > .total-section > div:last-child {
    font-size: 16px;
  }
  .cn-preview .bill-details-section .inv-date-wrap {
    margin-top: 0;
  }
  #invoice .table-section {
  }
  .amt-received-column {
    flex-direction: column;
  }
  .amout-received-digits span {
    text-align: left;
  }
  .show-excess-amt {
    margin-top: 20px;
  }
  .transaction-preview {
    padding: 25px 24px !important;
  }
  #invoice.invoice-preview .billed-to,
  #invoice.invoice-preview .ship-to,
  #invoice.invoice-preview.bill-details-section .inv-date-wrap,
  #invoice.invoice-preview .bill-details-section .payment-terms {
    width: 50% !important;
    flex: 0 0 50% !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
