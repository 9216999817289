:root {
    --red: #ed2a2a;
    --cornflower-blue: #5491ff;
    --gamboge: #e89c05;
    --amethyst: #a879d5;
    --crusoe: #026e26;
    --charcoal-grey: #323e48;
    --white-fill: #ffffff;
    --slate: #91919c;
    --battleship-grey: #747d84;
    --cerulean-blue: #0d5ae5;
    --booger: #9ac23c;
    --white: #ffffff;
    --white-smoke:#f5f5f5;
    --warm-white: #f2f2f2;
    --rose-white: #fff7f7;
    --solitude: #e3efff;
    --heather: #adb5bd;
    --whisper: #e9e9e9;
    --alice-blue: #e7f1ff;
    --approx-white: #fefefe;
    --salmon: #ff7979;
    --macaroni-cheese: #ffbe76;
    --apple: #6ab04c;
    --approx-whisper:#eee;
    --pink: #ffc7c7;
    --vista-blue: #a3d7b5;
    --black: #000;
    --approx-alice-blue: #f7fcff;
    --night-rider: #333333;
    --chambray: #3c5578;
    --pale-conflower-blue: #bad7ff;
    --new-whisper: #e7e7e7;
    --new-whisper2: #e6e6e6;
    --new-alice-blue: #f3f8ff;
    --navy-blue: #1660e6;
    --approx-apple: #4eae37;
    --new-approx-alice-blue: #f9fbff;
    --pattens-blue: #d0e6f7;
    --snow-white:#fafafa;
    --bunting:#28344a;
    --blanched-almond:#fbe7cf;
    --lynch:#747D84;
    --approx-cornflower-blue:#70a3ff;
    --new-cornflower-blue:#4c85eb;
    --lavender:#e5e9f9;
    --new-approx-alice-blue-2:#f7f9fb;
    --coral-red:#ff4040;
    --atlantis:#0041b5;
    --dodger-blue: #0d6efd;
    --granny-apple:#c4eed2;
    --medium-sea-green:#20bf6b;
    --new-white-smoke:#efefef;
    --light-grey:#d4d4d4;
    --ghost-white :#eef0ff;
    --very-light-grey :#ccc;
    --link-water:#c6cbd4;
    --deep-green: #026e26;
    --yellow-green: #58cf41;
    --tomato: #ff5f4c;
    --lavender-blue:#847aff;
    --new-approx-alice-blue2: #f6faff;
    --light-white-smoke: #f1f1f1;
    --tinted-grey:#d4e2e8;
    --arsenic:#323E48;
    --champagne: #fbe7cf;
    --nobel:#9b9b9b;
    --new-approx-alice-blue3: #f8fbff;
    --black-rgb: 0, 0, 0;
    --rich-bloack: #020202;
    --white-rgb: 255, 255, 255;
    --charcoal-grey-rgb: 50, 62, 72;
    --peak-point-rgb: 116, 125, 132;
    --slate-rgb: 145, 145, 156;
    --cerulean-blue-rgb: 13, 90, 229;
    --salmon-rgb: 255, 165, 165;
    --lavendar-blush-rgb: 255, 99, 132;
    --alice-blue-rgb: 53, 162, 235;
    --padua-approx : 196, 238, 210;
    --battleship-grey-rgb: 116, 125, 132;
    --black-shadow0: rgba(0, 0, 0, 0);
    --black-shadow1: rgba(0, 0, 0, 0.01);
    --black-shadow2: rgba(0, 0, 0, 0.02);
    --black-shadow3: rgba(0, 0, 0, 0.03);
    --black-shadow5: rgba(0, 0, 0, 0.05);
    --black-shadow6: rgba(0, 0, 0, 0.06);
    --black-shadow8: rgba(0, 0, 0, 0.08);
    --black-shadow11: rgba(0, 0, 0, 0.11);
    --black-shadow15: rgba(0, 0, 0, 0.15);
    --white-shadow8: rgba(255, 255, 255, 0.8);    
    --cerulean-blue-shadow2: rgba(13, 90, 229, 0.2);
    --cerulean-blue-shadow4: rgba(13, 90, 229, 0.4);
    --peak-point-shadow7: rgba(116, 125, 132, 0.07);
    --black-rgb-percent1: rgb(0 0 0 / 1%);
    --crayola-percent25 : rgb(13 110 253 / 25%);





  }

  
  
  /* rgba(0, 0, 0, 0.01); */

  

  /* rgb(0 0 0 / 1%);
  rgba(0, 0, 0, 0.01)
  rgb(13 110 253 / 25%) */