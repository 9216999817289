.plan-form .item-container {
  min-height: 80px;
  width: 31%;
  }
  
  .plan-form .item-number {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .plan-form .item-details {
    display: flex;
    color: grey;
    margin-bottom: 5px;
  }

  .plan-form .item-details span {
    font-size: 12px
  }
  
  .plan-form .item-flex-container {
    display: flex;
    justify-content: space-between;
  }

  .plan-form .box {
    margin-top: 25px;
  }
  .plan-form .wd-33 {
    width: 33%
  }

  .form-plan-header {
    display: inline-flex;
  }

  .form-plan-header .plan-header-org-span{
    margin-top: 6px;
    margin-left: 16px;
  }

.item-details .plan-item-rate {
    padding-left: 10px;
   }

.item-details .plan-item-rate span{
     font-weight: bold;
  }

.item-details .remove-padding{
    padding-left: 0px;
}
.item-details .remove-padding span{
  font-weight: bold;
}

.plan-form .reusable-form-input-field .custom-tooltip-wrapper .info-btn {
    left: 0;
  }
  
.plan-form .reusable-form-input-field .custom-tooltip-wrapper {
    float: right;
    margin-right: 2px;
  }