.discount-input {
  height: 40px;
  /* border: solid 1px var(--new-whisper) !important; */
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background: var(--white-fill) !important;
  position: relative !important;
  padding: 15px 12px 14px 12px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  text-align: left !important;
  margin: 0 !important;
  flex: 1;
}
.label-wrapper .discount {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.discount-info-icon {
  line-height: 30px !important;
  top: 3px !important;
}
.discount-type-wrapper {
  width: fit-content !important;
  height: 25px !important;
  margin-bottom: 8px;
}
.discount-type-wrapper button {
  height: 23px !important;
}
.discount-selector.accounts-dropdown.dropdown-menu {
  min-width: 100% !important;
}
.discount-select-dropdown-input.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
  border-radius: 6px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
/* .discount-field-wrapper {
  border-radius: 6px;
  border: solid 1px var(--new-whisper) !important;
}
.discount-field-wrapper.error {
  border: solid 1px var(--red);
} */
.discount-input.error {
  border: solid 1px var(--red) !important;
  border-right: 0 !important;
  background-color: var(--rose-white) !important;
  border-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.discount-selector input {
  font-size: 14px !important;
}
.discount-account-dropdown {
  flex: 3;
}
.discount-select-dropdown-input {
  position: relative;
  width: 100% !important;
  height: 40px;
  border-radius: 6px !important;
  border-left: 1px solid var(--new-whisper) !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: var(--white-fill)
    url("../../../../../assets/images/arrow-drop-right.svg") no-repeat right 4px
    center !important;
}
.discount-select-dropdown-input[aria-expanded="true"] {
  background: var(--white-fill)
    url("../../../../../assets/images/arrow-drop-right-inverted.svg") no-repeat
    right 4px center !important;
}
@media (max-width: 1024px) {
  .discount-info-icon {   
    top: 0px !important;
  }
}