.add-vendor-link {
    margin-top: 0;
    appearance: none;
    border: 0;
    box-shadow: none;
    padding-right: 0px;
    background-color: transparent;
    color: var(--cerulean-blue);
    float: right;
  }
 #addVendorModal .reusable-modal-btn {
    justify-content: flex-end !important;
  }
.add-vendor-link span{
  width:100%;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}