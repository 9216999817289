.subscription-impact-details-container {
  margin-bottom: 11px;
  padding: 0px 20px 0px 20px;
  --bs-gutter-x: 0 !important;
  border-radius: 3px;
  min-height: 49px;
  background-color: #f1f7ff;
  border: 1px solid #bad7ff;
  align-items: center;
  display: flex;
  max-width: max-content !important; 
}

.subscription-impact-update-conatiner {
  padding: 3px 20px 3px 20px;
  --bs-gutter-x: 0 !important;
  border-radius: 3px;
  background-color: #b4dcff;
  align-items: center;
  display: flex;
  max-width: max-content !important; 
}

.m-l-10{
  margin-left: 10px;
}

.m-t-24{
 margin-top: 24px;
}
.invoice-now-button {
  font-size: 15px;
  font-weight: bold;
  line-height: normal;
  padding-right: 8px;
  padding-bottom: 9px;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
  text-decoration: none;
}

.unbilled-charges-list .delete-col span {
  position: absolute;
  right: 0 !important;
  margin-right: 23px;
}