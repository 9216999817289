.reporting-tag-wrapper {
    width: 100%;
    padding: 24px 20px;
    border-radius: 4px;
    border: 1px solid var(--new-whisper);
}
.select-reporting-tag-wrapper {
    clear: both;
    width: 100%;
}
.select-tag-wrapper {
    width: 100%;  
    flex-direction: column;  
}
.select-tag-wrapper .column {
    width: 550px;
    /* flex: 0 0 212px; */
    padding-right: 0;
}
.select-tag-wrapper .column .amt-split-type {
    padding: 0 !important;
}
.selected-tags-wrapper {
    width: 100%;
    clear: both;
    max-height: calc(100vh - 524px);
    overflow: hidden;
    overflow-y: auto;
}
.reporting-tags-form h3 {
    font-size: 15px;
    font-weight: bold;  
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--charcoal-grey);
}
.select-reporting-tags-form h3 {
    margin-top: -6px;
    margin-bottom: 30px;
}
.select-tag-wrapper .select-type {
    padding-top: 28px;
}
.select-tag-wrapper .button-conatiner {
    padding-top: 26px;
    /* flex: 0 0 100px; */
    padding-right: 0;
}
.selected-tags-wrapper {
    margin-top: 26px;
    clear: both;
}
.selected-tags-wrapper .col {
    padding: 4px 8px 4px 0 !important;
    flex: 0 0 212px;
    line-height: 40px;
}
.selected-tags-wrapper .col:last-child {
    flex: 0 0 100px;
}
.selected-tags-wrapper .reporting_tag_name {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal; 
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: var(--charcoal-grey);
}
.selected-tags-wrapper .split-amt {
    padding: 0 12px;
    height: 42px;
    font-size: 14px;
    font-weight: normal;  
    line-height: 42px;
    letter-spacing: normal;
    text-align: left;
    color: var(--charcoal-grey);
    border-radius: 2px;
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
}
.selected-tags-wrapper .split-amt:disabled {
    border: 0;
    border: solid 1px var(--warm-white);
    background-color: var(--warm-white);
}
.selected-tags-wrapper .removeItem {
    display: inline-block;
    width: 24px;
    height: 32px;
    padding: 7px 6px 7px 0;
    line-height: 1;
}
.selected-tags-wrapper .removeItem img {
    width: 18px;
    height: auto;
}
.show-amount-small { 
    display: block;   
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--battleship-grey);
    float: right;
    clear: both;margin-bottom: 5px;
}