.customize-report-wrapper {
  width: 768px;
  padding: 0px 40px 40px 40px;
}
.customize-report-wrapper.regular-side-modal {
  width: 480px;
  padding: 0px 40px 40px 40px;
}

.customize-report-wrapper .alert-msg.info-alert-masg {
  background-color: var(--pale-conflower-blue);
}
.customize-report-wrapper .alert-msg {
  width: 100%;
  max-width: 522px;
  min-height: 26px;
  line-height: 100%;
  border-radius: 4px;
  padding: 10px;
  margin-top: 8px;
  flex: 0 0 100%;
}
.customize-report-wrapper .alert-msg img {
  margin-right: 4px;
}
.customize-report-select {
  border: none;
}
.customize-report-wrapper .daterange-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.daterangepicker {
  z-index: 1500 !important;
}
.customize-report-wrapper .daterange-wrapper {
  clear: both;
}
.delete-button {
  width: 30px;
  padding: 5px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.customize-report-wrapper .add-filter-section .select-field-wrap {
  width: 32%;
  flex: 0 0 32%;
}
.customize-report-wrapper .add-filter-section .transaction-type-select {
  width: 47%;
  flex: 0 0 47%;
}

.customize-report-wrapper .add-filter-section .trans-type-item {
  width: 95%;
  flex: 0 0 95%;
}
.add-filter-section {
  border: 1px solid var(--new-whisper);
  margin: 10px 0px;
  padding: 10px 0px;
  border-radius: 10px;
}
.add-filter-error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
.customize-report-wrapper .multiSelectContainer .searchBox {
  width: 100% !important;
}
.MuiButtonBase-root.MuiCheckbox-root {
  padding: 0px !important;
  padding-right: 10px !important;
}
.MuiCheckbox-root.Mui-checked {
  color: var(--cerulean-blue) !important;
}
.aging-report-wrapper {
  width: 768px;
  padding: 0px 40px 40px 40px;
}
.aging-report-wrapper .daterange-wrapper {
  width: 100%;
  margin-bottom: 20px;
}
.aging-report-wrapper .daterange-wrapper {
  clear: both;
}
.aging-report-wrapper .organization-dropdwon,
.aging-report-wrapper .organization-dropdwon .dropdown-toggle {
  width: 100% !important;
  margin-right: 0;
}
.aging-report-wrapper .organization-dropdwon .dropdown-toggle {
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.aging-report-wrapper .organization-dropdwon .dropdown-toggle:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.aging-report-wrapper .organization-dropdwon .dropdown-toggle .oraganization {
  font-size: 14px;
  font-weight: normal;
  color: var(--charcoal-grey);
  margin-top: 1px;
  padding-left: 13px;
}
.aging-report-wrapper .organization-dropdwon .dropdown-toggle:after {
  background: var(--white-fill)
    url("../../../../assets/images/arrow-drop-right.svg") no-repeat right 4px
    center !important;
  background-size: 20px 20px !important;
}
.aging-report-wrapper .organization-dropdwon .dropdown-menu {
  width: auto;
  min-width: 100%;
  inset: 0px auto auto 0px !important;
  padding: 0 !important;
  transform: translate3d(0px, 44px, 0px) !important;
}
.aging-report-wrapper .save-filter-label {
  margin: 0 44px 3px 12px;
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #323e48;
}
.aging-report-wrapper .checkbox-wrap {
  width: 100%;
  margin-bottom: 20px;
  clear: both;
}
