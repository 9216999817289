html {
  scroll-behavior: smooth;
}
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--white-fill);
}
* {
  box-sizing: border-box;
}
body {  
  font-size: 14px;
  font-weight: normal;
  line-height: normal; 
  letter-spacing: normal;
}
body,
body * {
  font-family: "Lato", sans-serif !important;
  font-stretch: normal !important;
  font-style: normal !important;    
  -webkit-font-smoothing: antialiased !important; 
  -moz-osx-font-smoothing: grayscale !important; 
} 
input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.mandatory {
  color: var(--red);
  margin-left: 2px;
}
.error {
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
}
.d-init {
  display: initial !important;
}