.amount-in-words-config .textFieldMui {
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: var(--new-whisper2);
  border: 1px solid var(--new-whisper2);
  border-radius: 6px;
  margin-bottom: 5px;
}
.amount-in-words-config input:focus {
  border-color: var(--white-fill) !important;
}
.amount-in-words-config .MuiBox-root {
  margin-left: 60px !important;
}
.amount-in-words-config .MuiFormControlLabel-root {
  padding: 12px;
  margin-bottom: 0;
}
.amount-in-words-config .MuiTypography-root {
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--charcoal-grey) !important;
}
.amount-in-words-config .MuiTypography-root:hover {
  color: var(--dodger-blue) !important;
}
.amount-in-words-config .checkbox {
  margin-right: 5px;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px var(--slate);
  background-color: var(--white-fill);
  padding: 0;
  margin-right: 12px;
}
.amount-in-words-config .checkBoxChecked {
  background-color: var(--cerulean-blue) !important;
  background-image: url(../../../../../../assets/images/checkbox_check.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 7px 6px;
}
.amount-in-words-config .checkBoxPartiallyChecked {
  background-image: url(../../../../../../assets/images/partialSselected.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.amount-in-words-config .checkbox:hover {
  border: solid 1px var(--dodger-blue) !important;
}
.amount-in-words-config .no-options-label {
  color: grey;
  width: 100%;
  text-align: center;
}
.amount-in-words-config input[type="text"] {
  border: 0 !important;
}
.popoverElement-MuiPopover-paper > .MuiPopover-paper {
  outline: 0;
}
#reportingtag-popover {
  z-index: 100000 !important;
}
.amount-in-words-config input.MuiInputBase-input {
  width: 100% !important;
  height: 20px;
  padding: 12px 14px;
  border-radius: 6px;
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: rgba(50, 62, 72, 0.55);
}
.amount-in-words-config .errorText {
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
  border-color: var(--red) !important;
}
.amount-in-words-config .error {
  color: var(--red);
  font-size: 13px;
  line-height: 19px;
  border-color: var(--red) !important;
  background-color: var(--rose-white) !important;
}
.amount-in-words-config .error input.MuiInputBase-input {
  background-color: var(--rose-white) !important;
}
.amount-in-words-config .disable {
  content: "";
  opacity: 0.5;
  background-color: var(--white-fill);
  z-index: 10;
  pointer-events: none;
}
