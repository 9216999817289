.desc-area {
  width: 293px !important;
}
.reusable-form-item-wrapper {
  width: 100%;
}
.form-field {
  width: 100%;
  padding-right: 2rem;
  margin-bottom: 20px;
}
.label-wrapper {
  width: 100%;
  clear: both;
}
.label-wrapper .form-field-label {
  color: var(--charcoal-grey);
  float: left;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-bottom: 12px;
}
.label-container-infotip {
  display: flex;
  align-items: center;
  height: 25px;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .reusable-form-item-wrapper.subject-col {
    width: 50%;
  }
}
