.prof-los-report .filter-box .filter-wrapper {
    justify-content: flex-start;
}
.prof-los-report .filter-box .filter-wrapper .filter-item {
    margin-right: 6.3%;
    width: 29.9824%;
}
.prof-los-report .filter-box .filter-wrapper .filter-item:last-child {
    margin-right: 0;
}
.prof-los-report .report-body-links {
    position: relative;    
}
.prof-los-report .report-body-links:before {
    content: '';
    position: absolute;
    width: calc(100% + 60px);
    height: 1px;
    top: 0;
    left: -30px;
    border-top: 1px solid var(--approx-whisper);
}
