.reporting-tags-dropdown {
    width: 100%;
    max-height: calc(100vh - 365px);
    overflow: hidden;
    overflow-y: auto;
}
.reporting-tags-dropdown .textFieldMui {
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: var(--new-whisper2);
    border: 1px solid var(--new-whisper2);
    border-radius: 6px;
    margin-bottom: 5px;
}
.reporting-tags-dropdown input:focus {
    border-color: var(--white-fill) !important;
}
.popoverElement>.MuiPaper-root {
    width: 100%;
    padding: 5px 10px 5px 10px;
    padding: 12px;
    box-shadow: 0px 0px !important;
    border: solid 1px var(--new-whisper) !important;
    background-color: var(--white-fill);
    border-radius: 6px;    
    margin-top: 6px;
}
.reporting-tags-dropdown .MuiBox-root {
    margin-left: 12px !important;
}
.reporting-tags-dropdown .MuiFormControlLabel-root {
    padding: 12px;
}
.reporting-tags-dropdown .MuiTypography-root {
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--charcoal-grey) !important;
}
.reporting-tags-dropdown .MuiTypography-root:hover {
    color: var(--dodger-blue) !important;
}
.reporting-tags-dropdown .checkbox {
    margin-right: 5px;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid 1px var(--slate);
    background-color: var(--white-fill);
    padding: 0;
    margin-right: 12px;
}
.reporting-tags-dropdown .checkBoxChecked {
    background-color: var(--cerulean-blue) !important;
    background-image: url(../../../../assets/images/checkbox_check.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 7px 6px;
}
.reporting-tags-dropdown .checkBoxPartiallyChecked {
    background-image: url(../../../../assets/images/partialSselected.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.reporting-tags-dropdown .checkbox:hover {
    border: solid 1px var(--dodger-blue) !important;
}
.reporting-tags-dropdown .no-options-label {
    color: grey;
    width: 100%;
    text-align: center;
}
.reporting-tags-dropdown input[type="text"] {
    border: 0 !important;
}
.popoverElement-MuiPopover-paper>.MuiPopover-paper {
    outline: 0;
}
#reportingtag-popover{
    z-index:100000!important;
}
.reporting-tags-dropdown input.MuiInputBase-input {
    width: 100% !important;
    height: 20px;
    padding: 12px 14px;
    border-radius: 6px;    
    background-color: var(--white-fill);
    font-size: 14px;
    font-weight: normal;  
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: rgba(50, 62, 72, 0.55);
}
.reporting-tags-dropdown .errorText {
    color: var(--red);
    font-size: 13px;
    display: block;
    line-height: 19px;
    border-color: var(--red) !important;
}
.reporting-tags-dropdown .error {
    color: var(--red);
    font-size: 13px;
    line-height: 19px;
    border-color: var(--red) !important;
    background-color: var(--rose-white) !important;
}
.reporting-tags-dropdown .error input.MuiInputBase-input {
    background-color: var(--rose-white) !important;
}
.reporting-tags-dropdown .disable {
    content: "";
    opacity: 0.5;
    background-color: var(--white-fill);
    z-index: 10;
    pointer-events: none;
}