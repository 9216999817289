.report-table-section .expandable-table .column-mapping {
  width: 210px !important;
  min-width: 210px !important;
  max-width: 220px !important;
}
.expandable-table {
  width: 100% !important;
  overflow-x: auto !important;
}
table.expandable-table th {
  color: var(--battleship-grey) !important;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 2;
  text-transform: uppercase;
}
table.expandable-table th td {
  min-width: 210px !important;
  max-width: 220px !important;
}
table.expandable-table th {
  min-width: 210px !important;
  max-width: 220px !important;
}
.expandable-table td:nth-child(1) {
  position: sticky !important;
  left: 0 !important;
  background-color: white;
  padding-left: 10px !important;
}
.report-table-section .expandable-table tr td:nth-child(1) {
  min-width: 280px;
  padding: 0;
  border-top: 1px solid var(--warm-white) !important;
}
.report-table-section .expandable-table th:first-child {
  position: sticky !important;
  left: 0 !important;
  background-color: white;
}
.report-table-section .expandable-table tr td {
  border-top: 1px solid var(--warm-white) !important;
}
.report-table-section .expandable-table tr.cell-highlighter td:first-child {
  padding: 0 0px !important;
}
.report-table-section .expandable-table tr td:first-child .cell-highlighter {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.account-column {
  position: sticky !important;
  left: 0 !important;
  background-color: white;
}
table.expandable-table tr:hover,
table.expandable-table tr:hover {
  box-shadow: none !important;
}
table.expandable-table tbody tr:nth-child(even) td {
  background-color: var(--white-fill);
}
.cell-highlighter {
  background-color: var(--white-smoke) !important;
}
table.expandable-table tbody tr.cell-highlighter:nth-child(even) td {
  background-color: var(--white-smoke) !important;
}
table.profit-loss-table tr th:first-child {
  text-align: left !important;
}
table.profit-loss-table tr th {
  text-align: right !important;
}

table.expandable-table tbody tr.table-navigation.row-navigation {
  cursor: pointer;
}
table.expandable-table tbody tr.table-navigation.row-navigation:hover td {
  color: var(--cerulean-blue) !important;
}
