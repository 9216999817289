.subscription-form .list-card-header {
    background-color: var(--approx-white) !important;
  }
  
  .subscription-form .invoice-list-card-header {
    padding-bottom: 23px !important;
  }
  .subscription-form .invoice-list-card-header h1 {
    margin-top: 7px !important;
  }
  .subscription-form .create-invoice-button {
    width: auto;
    padding: 0 15px 0 8px;
  }
  .subscription-form .create-invoice-button svg.MuiSvgIcon-root {
    margin-top: -4px !important;
    margin-right: 6px;
  }
  
  .subscription-form .table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .subscription-form .card-list #invoice_list_table .action .dropdown-menu {
    inset: 19px -10px auto auto !important;
  }
  
  .subscription-form .card-customer-header h1 {
    font-size: 24px;
    font-weight: 900;
  
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    float: left;
    margin-bottom: 0;
  }
  
  .subscription-form .tag-box {
    width: auto;
    min-width: 47px;
    height: 20px;
    margin: 0 8px 8px 0;
    padding: 0 8px;
    border-radius: 4px;
    border: solid 1px var(--pattens-blue);
    background-color: var(--approx-alice-blue);
  
    font-size: 13px;
    line-height: 1.54;
    color: var(--battleship-grey);
    float: left;
  }
  
  .subscription-form .tag-count {
    margin: 0;
    padding: 0 6px;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
    box-shadow: 0 2px 6px 0 var(--black-shadow1);
    border: solid 1px var(--cerulean-blue-shadow2);
    background-color: var(--new-alice-blue);
  
    font-size: 13px;
    font-weight: normal;
  
    line-height: 1.54;
    letter-spacing: normal;
    color: var(--cerulean-blue);
  }
  .subscription-form #invoice-table-wrapper th.tags,
  #invoice-table-wrapper td.tags,
  #invoice-table-wrapper th.email,
  #invoice-table-wrapper td.email {
    white-space: nowrap;
  }
  .subscription-form .popover {
    border-radius: 1px;
    -webkit-box-shadow: 0 2px 8px 0 var(--black-shadow5);
    box-shadow: 0 2px 8px 0 var(--black-shadow5);
    border: solid 1px var(--new-whisper);
    background-color: var(--white-fill);
    max-width: 50%;
  }
  
  .subscription-form .popover-body {
    width: 100%;
    padding: 10px 12px 0 12px;
    float: left;
    border-radius: 1px;
  }
  
  .subscription-form .popover .tag-box {
    margin-bottom: 10px;
  }
  
  .subscription-form .frequency-container {
    display: flex;
  }
  
  .subscription-form .frequency-wrapper {
    width: 65% !important;
    display: flex;
  }
  
  .subscription-form .width-35 {
    width: 35%;
  }
  
  .billing_cycle_fixed_count_class {
    height: 41px;
    margin-top: 29px;
    margin-left: 30px;
    border-color: var(--new-whisper);
  }
  
  .custom_repeat_count_width_fix {
    height: 43px;
  }
  
  
  /** Media Queries **/
  
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 768px) and (max-width: 991px) {
    /* .invoice-list-card-header .filter-componet-wrapper .filter-menu {
      left: auto !important;
      right: 0 !important;
    } */
  }
  @media (min-width: 992px) and (max-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .subscription-form-footer #addVendorModal
      .expanded-submenu
      #add_customer_form
      .form-button-wrapper.docked-button-container,
    #addVendorModal
      .expanded-submenu
      #add_vendor_form
      .form-button-wrapper.docked-button-container {
      position: static !important;
      width: 100% !important;
      padding-left: 25px !important;
      box-shadow: none !important;
      padding-bottom: 24px !important;
    }
  }
  
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  }
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  .recurring-invoice-view .customer-detail-card .tab-content-overview {
    padding-bottom: 30px !important;
  }
  
  .form-subscription-header {
    display: inline-flex;
  }
  
  .form-subscription-header .subscription-header-org-span {
    margin-top: 6px;
    margin-left: 16px;
  }

  .form-subscription-header .subscription-header-org-span {
    margin-top: 6px;
    margin-left: 16px;
  }

  @media (min-width: 1315px) and (max-width: 1315px){
    .subscription-form .subscription-field-alignment {
      width: 90%;
    }
  }
  @media (min-width: 1316px) and (max-width: 1700px){
    .subscription-form .subscription-field-alignment {
      width: 80%;
    }
  }

  @media (min-width: 1700px) and (max-width: 2220px){
    .subscription-form .subscription-field-alignment {
      width: 56%;
    }
  }

  @media (min-width: 2220px) {
    .subscription-form .subscription-field-alignment {
      width: 40%;
    }
  }

.h-89 {
  height: 89px;
}