/* .option-div {
  width: fit-content;
} */
.addon-pricing-form .radio-container {
  display: flex;
  width: 100%;
}

.addon-pricing-form .radio-div {
  width: 15%;
  /* display: flex; */
  text-align: center;
}

.addon-pricing-form .trial-period-count {
  /* width: 71px !important; */
  width: 89px !important;
}

.addon-pricing-form .trial-period-count-radius {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.addon-pricing-form .trial-radio {
  transform: scale(1.5);
}

.addon-pricing-form .trial-period-height > .css-1s2u09g-control,
.trial-period-height > .css-1pahdxg-control {
  height: 42px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.addon-pricing-form .show-desc-inv {
  margin-left: 18px;
}

.addon-pricing-form .show-desc-container {
  margin-top: 10px;
}

.addon-pricing-form .show-desc-inv-chk {
  transform: scale(1.3);
}

.addon-pricing-form .pricing-model-dp {
  width: 293px !important;
}

.addon-pricing-form .pricint-model-wrap .relative-wrapper {
  margin-left: 15px;
}

.addon-pricing-form .item-container {
  min-height: 128px;
}

.addon-pricing-form .item-cont-width {
  min-width: 100%;
}

.addon-pricing-form .item-cart {
  min-height: 128px;
  background-color: #e9f2fd;
  max-width: 70%;
  margin-left: 19px;
}

.addon-pricing-form .border-container {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 21px;
}

.addon-pricing-form .border-div {
  width: 90%;
  border-bottom: 1px solid #d6e8fd;
}

.addon-pricing-form .item-header {
  padding: 10px;
}

.addon-pricing-form .item-price {
  width: 115px;
}

.addon-pricing-form .item-price-cont {
  display: flex;
  padding-bottom: 10px;
  width: inherit;
}

.addon-pricing-form .pricing-field-wrap {
  display: flex;
  width: inherit;
}

.addon-pricing-form .item-price-cont .form-input-icon-wrapper {
  margin-top: 0px;
}

.addon-pricing-form .total-container {
  padding-left: 18px;
  margin-top: 10px;
}

.addon-pricing-form .item-price-cont .reusable-form-input-field {
  width: 150px;
}

.addon-pricing-form .close-row-container {
  display: flex !important;
  text-align: center;
  justify-content: center;
  padding-top: 37px;
}

.addon-pricing-form .add-row-container {
  padding-left: 3px;
}

.addon-pricing-form .close-row {
  cursor: pointer;
}

.addon-pricing-form .pricing-field-wrap-per-unit .reusable-form-input-field {
  width: 150px;
}

.addon-pricing-form .pricing-field-wrap-per-unit {
  position: relative;
}

.addon-pricing-form .pricing-field-wrap-per-unit > img {
  position: absolute;
  right: 8px;
  top: 1px;
}

.addon-pricing-form .trial_count_wrapper_plan_price {
  display: flex;
  margin-top: 10px;
}

.addon-pricing-form .trial_count_wrapper_plan_price .trial-period-count-radius {
  height: 42px;
}

.addon-pricing-form .trial_count_wrapper_plan_price .css-b62m3t-container {
  margin-left: -12px;
  width: 130px !important;
}

#trial_frequency .css-1s2u09g-control {
  width: 106px !important;
  border: solid 1px var(--new-whisper);
}

#trial_frequency .css-1pahdxg-control {
  width: 106px !important;
}

.addon-pricing-form .relative-wrapper .custom-tooltip-wrapper .info-btn,
.addon-pricing-form
  .reusable-form-input-field
  .custom-tooltip-wrapper
  .info-btn {
  left: 0;
}

.addon-pricing-form .relative-wrapper .custom-tooltip-wrapper {
  float: right;
  margin-right: 20px;
}

.addon-pricing-form .reusable-form-input-field .custom-tooltip-wrapper {
  float: right;
  margin-right: 2px;
}

@media (max-width: 1024px) {
  .addon-pricing-form .relative-wrapper .custom-tooltip-wrapper {
    float: right;
    margin-right: 12px;
  }
}
