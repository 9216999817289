.subscription-plan-popover .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  max-width: 400px;
  min-width: 350px;
  border: solid 1px var(--warm-white);
  box-shadow: none;
}
.subscription-plan-popover .plan-card {
  max-width: 400px;
  min-width: 350px;
  margin: 0px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  padding: 10px 15px 32px 32px !important;
  background-color: var(--white-fill);
  border-radius: 12px;
  border: solid 1px var(--warm-white);
}

.subscription-plan-popover .plan-card .card-header {
  margin-bottom: 12px;
}

.subscription-plan-popover .plan-card .plan-card-header-wrapper {
  width: 100% !important;
}
.plan-card .plan-card-header-wrapper .justify-content-center {
  width: 100% !important;
}
.plan-card .plan-card-header-wrapper .justify-content-center h1 {
  width: 100% !important;
}
.subscription-plan-popover .form-button-wrapper {
  border: 0px;
  padding-left: 4px !important;
  margin-top: 20px;
  height: 70px;
}
.plan-card-header-wrapper .popover-close-button .close-btn {
  float: right;
}
.plan-card .card-body {
  margin-right: 17px;
}

.subscription-addon-popover .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 12px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  max-width: 400px;
  min-width: 350px;
  border: solid 1px var(--warm-white);
  box-shadow: none;
}

.subscription-addon-popover .addon-card {
  max-width: 400px;
  min-width: 350px;
  margin: 0px;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  padding: 10px 15px 32px 32px !important;
  background-color: var(--white-fill);
  border-radius: 12px;
  border: solid 1px var(--warm-white);
}

.subscription-addon-popover .addon-card .card-header {
  margin-bottom: 12px;
}

.subscription-addon-popover .form-button-wrapper {
  border: 0px;
  padding-left: 4px !important;
  margin-top: 20px;
  height: 67px;
}

.addon-card .card-body {
  margin-right: 17px;
}
.subscription-addon-popover .addon-card .addon-card-header-wrapper {
  width: 100% !important;
}
.addon-card .addon-card-header-wrapper .justify-content-center {
  width: 100% !important;
}
.addon-card .addon-card-header-wrapper .justify-content-center h1 {
  width: 100% !important;
}

.subscription-addon-popover .form-button-wrapper {
  border: 0px;
  padding-left: 2px !important;
  margin-top: 30px;
}

.addon-card-header-wrapper .popover-close-button .close-btn {
  float: right;
}

.p-t-0 {
  margin-top: 0px;
}
.m-t-10 {
  margin-top: 10px;
}

.p-t-15 {
  padding-top: 10px;
}

.p-b-18 {
  padding-bottom: 18px;
}
.subscription-add-coupon {
  width: 400px;
}
.m-t-20 {
  margin-top: 20px;
}

.subscription-add-coupon .side-modal-title {
  flex-direction: column;
}

.subscription-add-coupon .modal-body {
  padding: 0 30px 40px 30px;
  border-top: 1px solid var(--approx-whisper);
}
.subscription-add-coupon .modal-body .MuiList-root {
  padding: 0px;
}
.subscription-add-coupon .modal-body .MuiButtonBase-root {
  border-bottom: 1px solid var(--approx-whisper);
  margin: 0px 24px;
}

.subscription-add-coupon .modal-body .MuiButtonBase-root:hover {
  background-color: #e9f2fd;
}

#subscription_select_plan .select-cust-scroll__menu-list,
#subscription_select_addon .select-cust-scroll__menu-list {
  max-height: 180px !important;
}

#subscription_billing_frequency .billing-frequency-select__menu-list {
  max-height: 90px !important;
}

.subscription-add-coupon-button .add-tags-button {
  padding: 0px;
  margin: 0px;
  line-height: 20px !important;
  border: 0px !important;
  background-color: white;
}
.subscription-add-coupon-button .add-coupon-button span {
  padding: 0px 10px;
}
.subscription-add-coupon-button .add-coupon-button img {
  pointer-events: none;
  cursor: pointer;
  margin-top: -3px !important;
}

.subscription-add-coupon-button {
  padding: 0px 20px 18px 16px;
  margin-bottom: 24px;
  margin-top: 19px;
}

.subscription-add-coupon-details .item-grid-account .item-row {
  flex-direction: row !important;
}

.subscription-add-coupon-details .item-grid-account {
  min-width: 500px !important;
}
.subscription-add-coupon-details .item-grid-account .coupon-detail-div {
  width: 80%;
  height: 40px;
}

#item-det-container .item-grid-account .coupon-detail-div .coupon-label {
  color: var(--charcoal-grey) !important;
  height: 20px !important;
  margin: 0px !important;
}

.subscription-form .item-row .add-detail-plan-name {
  font-weight: bold;
}

.subscription-form .item-row .add-detail-plan-name .plan-detail {
  font-weight: normal;
  font-size: 13px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-t-24 {
  padding-top: 24px;
}

.subscription-form .item-row .accounts-dropdown .dropdown-menu {
  min-width: 190px !important;
}

.subscription-form
  #item-details-wrapper
  .item-grid-account
  .each-item-detail-label
  label {
  color: black !important;
  margin-bottom: 0px !important;
}

.add-detail-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.subscription-add-coupon .tbody-container li {
  font-size: 14px !important;
  line-height: 2 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
}

.subscription-add-coupon .tbody-container ul {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 160px);
  min-height: calc(100vh - 160px);
}

.item-details-wrapper .exchange-rate-display-wrapper .info-tool-tip .info-btn {
  top: 1px !important;
  margin-right: 18px;
}

.item-details-wrapper
  .exchange-rate-display-wrapper
  .info-tool-tip
  .info-btn
  img {
  width: 16px;
}

.subscription-add-coupon .modal-body .coupon-menu-no-result {
  border-bottom: 1px solid var(--approx-whisper);
  margin: 0px 24px;
  padding: 6px 16px;
}

.update-subscription-date-disabled {
  background-color: var(--warm-white) !important;
}

.subscription-update-pricing-modal-footer .add-tags-button {
  padding: 0px;
  margin: 0px;
  line-height: 20px !important;
  border: 0px !important;
  background-color: #e9f2fd;
}

.subscription-update-pricing-modal-footer .add-tags-button:focus {
  padding: 0px;
  margin: 0px;
  line-height: 20px !important;
  border: 0px !important;
  background-color: #e9f2fd;
  outline: 0px !important;
}
.subscription-update-pricing-modal-footer .add-restore-button span {
  padding: 0px 10px;
  margin-top: 5px;
}

.subscription-update-pricing-modal-footer .restore-button {
  margin-right: 15px;
  margin-top: 7px;
}

.subscription-update-pricing-modal-footer
  .restore-button
  .custom-tooltip-wrapper {
  float: right;
  margin-right: 2px;
  margin-top: -2px;
}

.subscription-update-pricing-modal-footer
  .restore-button
  .custom-tooltip-wrapper
  .info-btn {
  left: 6px;
}

.subscription-item-details-container .item-inner-row {
  background: white;
  margin: 20px 0;
  padding: 0 8px;
  /* background-color: rgba(var(--cerulean-blue-rgb), 0.04); */
}

.subscription-item-details-container .item-inner-row .col-padding-left {
  padding-left: 2.568%;
}

.subscription-item-details-container .item-grid-account .item-level {
  max-width: 270px;
  min-width: 193px;
}


.subscription-item-details-container .tds-dropdown-container {
  margin-left: 30px;
}

.subscription-item-details-container .item-grid-account .per-unit-min-width {
  max-width: 312px;
  min-width: 193px;
}

.subscription-item-details-container .discount-type-wrapper {
    width: fit-content !important;
    height: 25px !important;
  }
  .subscription-item-details-container .discount-type-wrapper button {
    height: 23px !important;
  }
  .subscription-item-details-container .discount-type-wrapper {
    width: 82px !important;
  height: 22px;
  float: right;
  -webkit-box-shadow: 0 2px 1px 0 var(---black-shadow6);
  box-shadow: 0 2px 1px 0 var(---black-shadow6);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  border-radius: 4px;
  }
  .subscription-item-details-container .discount-type-wrapper button {
    width: 50%;
    height: 20px;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: normal;
    color: rgba(var(--charcoal-grey-rgb), 0.55);
    background-color: var(--white-fill);
    transition: 0.2s background ease-in-out;
    border-radius: 4px 0 0 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border: 0;
  }
  .subscription-item-details-container .discount-type-wrapper button.active {
    border-radius: 3px;
    background-color: var(--cerulean-blue);
    color: var(--white-fill);
  }