.card-body {
  padding: 0 !important;
}
.card-header-pills {
  padding-left: 0;
}
.card-active .underscore {
  width: 32px;
  height: 4px;
  background-color: var(--cerulean-blue);
  margin-top: 7px;
}
#user_table_wrapper div.dataTables_length select {
  width: 75px;
  height: 32px;
  margin: 0 0 0 8px;
  padding: 5px 3px 3px 11px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--approx-whisper);
  background-color: var(--white-fill) !important;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  background: url("../../../assets/images/dropdwon-arrow-down.svg") no-repeat !important;
  background-size: 24px 24px !important;
  background-position: right 2px center !important;
}
#user_table_wrapper div.dataTables_length label {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--slate);
  float: initial !important;
}
#user_table_wrapper {
  min-width: 100%;
  padding: 19px 0 0 0;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white) !important;
  background-color: var(--white-fill);
}
#user_table_wrapper div.dataTables_info {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-top: 3px;
}
#user_table_wrapper div.dataTables_info b {
  font-weight: bold;
}
#user_table {
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 0 0 var(--black-rgb-percent1);
  box-shadow: 0 6px 0 0 var(--black-rgb-percent1);
  background-color: var(--white-fill);
  margin-bottom: 0 !important;
  border: 0;
}
#user_table th,
#user_table td {
  padding: 13px 14px !important;
  padding-left: 0 !important;
}
#user_table th:first-child,
#user_table td:first-child {
  padding-left: 24px !important;
}
#user_table th,
#user_table th span {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
}
#user_table th span {
  display: inline-block;
  margin-right: -4px;
}
#user_table th[data-orderable="true"] {
  cursor: pointer;
}
#user_table th[data-orderable="true"] span {
  position: relative;
  padding-right: 24px;
}
#user_table th[data-orderable="true"] span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: left top;
  bottom: 4px;
  right: 8px;
}
#user_table th[data-orderable="true"] span:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: right top;
  right: 0;
  bottom: 4px;
}
#user_table th.ascending span:before {
  opacity: 1;
}
#user_table th.ascending span:after {
  opacity: 0.5;
}
#user_table th.descending span:before {
  opacity: 0.5;
}
#user_table th.descending span:after {
  opacity: 1;
}
#user_table td,
#user_table td span {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.71;
  color: var(--charcoal-grey);
  box-shadow: none;
}
#user_table td .ellipsis,
#role_table td .ellipsis {
  display: block;
  width: auto;
  max-width: 100%;
}
#user_table td:first-child {
  width: 17.564% !important;
  min-width: 120px;
}
#user_table td:nth-child(2) {
  width: 24.7132% !important;
  white-space: nowrap;
}
#user_table td:nth-child(3) {
  width: 29.5675% !important;
  white-space: nowrap;
}
#user_table td:nth-child(4) {
  width: 24.3601% !important;
  min-width: 150px;
}
#user_table td:last-child {
  padding-left: 0 !important;
}
#user_table tr th:last-child,
#user_table tr td:last-child {
  width: 43px !important;
}
#user_table tr.suspended {
  background-color: rgba(var(--salmon-rgb), 0.2);
}
.suspended-button {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  top: -1px;
  padding: 0 !important;
}
.suspended-button img {
  float: left;
}
.table-info {
  padding: 0 24px 16px 24px;
  --bs-gutter-x: 0 !important;
}
.data-info {
  font-size: 14px;
  color: var(--charcoal-grey);
  padding: 0 100px 0 0;
  height: 32px !important;
}
.results-per-page {
  font-size: 14px;
  color: var(--slate);
  text-align: right;
  height: 32px !important;
}
.form-select {
  background-position: right 3px center;
  /* padding: 3px 20px 3px 11px; */
  font-size: 14px;
}
div.dataTables_wrapper div.dataTables_info {
  padding-top: 0 !important;
  white-space: nowrap;
}
table.dataTable.table-striped > tbody > tr.odd > * {
  -webkit-box-shadow: inset 0 0 0 9999px var(--black-shadow0) !important;
  box-shadow: inset 0 0 0 9999px var(--black-shadow0) !important;
}
table.dataTable.table-striped > tbody > tr.even > * {
  -webkit-box-shadow: inset 0 0 0 9999px var(--peak-point-shadow7) !important;
  box-shadow: inset 0 0 0 9999px var(--peak-point-shadow7) !important;
}
tbody,
td,
tfoot,
tr {
  border-color: unset !important;
  border-style: none !important;
  border-width: 0 !important;
}
tr {
  height: 50px;
}
tr:not(tr.disabled):hover {
  -webkit-box-shadow: inset 0 0 0 9999px var(--solitude) !important;
  box-shadow: inset 0 0 0 9999px var(--solitude) !important;
}
th,
td {
  font-size: 15px;
  color: var(--charcoal-grey) !important;
  padding: 12px 12px 12px 23px !important;
}
thead,
th {
  font-size: 14px;
  font-weight: bold;
  color: var(--battleship-grey);
  border-top: solid 1px var(--warm-white);
  border-bottom: solid 1px var(--warm-white);
}
.green-button {
  position: relative;
  height: 40px;
  border-radius: 6px;
  background-color: var(--booger);
  line-height: 1;
}
.green-button:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.green-button:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(var(--black-rgb), 0.08);
}
.invite-user-button {
  min-width: 137px;
  margin: 0 16px 0 0;
  padding: 0 15px 0 10px;
  float: right;
}
.user-invite-button {
  border: none;
  background: none;
}
.all-roles-filter {
  margin: 0 16px 0 0;
}

.invite-user-button-text {
  margin: 0 0 0 3px;
}
.invite-user-button img {
  width: 24px;
  height: 24px;
  margin: 0 0 2px 0;
  object-fit: contain;
}
.card-user-management .all-roles-wrapper .all-roles-select {
  width: 145px !important;
}
.card-user-management .all-roles-wrapper select.all-roles-select {
  left: 0;
}
.card-user-management .all-roles-wrapper .all-roles-select button,
.card-user-management .all-roles-wrapper .custom-select div[class$="-control"] {
  width: 145px;
  height: 40px;
  position: relative;
  margin: 0;
  padding: 0 0 0 24px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--cerulean-blue-shadow2);
  background-color: var(--new-alice-blue);
  color: var(--navy-blue);
  background-image: url("../../../assets/images/roles_list.svg");
  background-position: left 8px center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  outline: 0 !important;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: normal;
  color: var(--navy-blue);
}
.card-user-management .all-roles-wrapper .custom-select input:focus {
  border: 0 !important;
}
.card-user-management .all-roles-wrapper .custom-select svg path {
  fill: var(--cerulean-blue);
}
.card-user-management .all-roles-wrapper .all-roles-select button:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  right: 8px;
  margin-top: -11px;
  background: url("../../../assets/images/blue-arrow-icon-down.svg") no-repeat
    center;
  background-size: 24px 24px;
  border: 0 !important;
}
.card-user-management .all-roles-wrapper .all-roles-select button.show:after {
  transform: rotate(-180deg);
}
.card-user-management .all-roles-wrapper .all-roles-select button:hover {
  color: var(--navy-blue);
}
.card-user-management .all-roles-wrapper .all-roles-select button img {
  margin: 2px 6px 6px 0;
}
.all-roles-button-text {
  width: 59px;
  height: 10px;
  margin: 7px 13px 7px 0;
  font-size: 15px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: normal;
  color: var(--navy-blue);
}
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-menu {
  transform: none !important;
  inset: unset !important;
}
.card-user-management .all-roles-wrapper .all-roles-select div.dropdown-menu {
  top: 48px !important;
  right: 0 !important;
}
.card-user-management .all-roles-wrapper .all-roles-select ul.dropdown-menu {
  padding: 0;
  width: 100%;
}
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item span {
  font-size: 14px;
  font-weight: normal;
  line-height: 2.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item {
  padding: 0 12px;
}
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item:active,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item:active span,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item.active,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item.active span,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item:focus,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item:focus span,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item:hover,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item.selected,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item:hover span,
.card-user-management .all-roles-wrapper .all-roles-select .dropdown-item.selected span {
  color: var(--cerulean-blue);
  background-color: transparent;
}
.all-roles-wrapper .all-roles-select .dropdown-item span {
  display: block;
  float: left;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Tags in Tags column */
.tag-box {
  width: auto;
  min-width: 47px;
  height: 20px;
  margin: 0 8px 8px 0;
  padding: 0 8px;
  border-radius: 4px;
  border: solid 1px var(--pattens-blue);
  background-color: var(--approx-alice-blue);
  font-size: 13px;
  line-height: 1.54;
  color: var(--battleship-grey);
  float: left;
}
.tag-count {
  margin: 0;
  padding: 0 6px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--cerulean-blue-shadow2);
  background-color: var(--new-alice-blue);
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
.popover {
  border-radius: 1px;
  -webkit-box-shadow: 0 2px 8px 0 rgba(var(--black-rgb), 0.05);
  box-shadow: 0 2px 8px 0 rgba(var(--black-rgb), 0.05);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  max-width: 50%;
}
.popover-body {
  width: 100%;
  padding: 10px 12px 0 12px;
  float: left;
  border-radius: 1px;
}
.popover .tag-box {
  margin-bottom: 10px;
}
.bs-popover-top > .popover-arrow:before {
  border-top-color: var(--new-whisper);
}
.bs-popover-bottom > .popover-arrow:before {
  border-bottom-color: var(--new-whisper);
}
.suspended-popover,
.suspended-popover-content {
  width: 234px !important;
  border-radius: 4px !important;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  border: solid 1px var(--new-whisper) !important;
  background-color: var(--white-fill) !important;
  padding: 0 !important;
  z-index: 1316 !important;
}
.suspended-popover .popover-body,
.suspended-popover-content .popover-body {
  padding: 12px 16px 14px 12px !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.suspended-msg {
  border-radius: 4px;
}
.suspended-msg h6 {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 8px;
}
.suspended-msg p {
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 14px;
}
.suspended-msg a {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--cerulean-blue);
  padding-left: 21px;
  margin-bottom: 0;
  text-decoration: none;
  cursor: pointer;
  background: url("../../../assets/images/blue-reload-icon.svg") no-repeat left
    center;
  background-size: 16px 16px;
  outline: 0;
}
.suspended-msg a span {
  position: relative;
  top: -1px;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
.action img,
.action svg {
  cursor: pointer;
}
.card-user-management .dropdown-menu {
  min-width: 135px;
  height: auto !important;
  text-align: left;
  position: absolute;  
  margin: 0px !important;
  padding: 14px 0 14px 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  margin-left: -65px;
}
/* .card-user-management .dropdown-menu:not(.kebabMenu .dropdown-menu) {
  inset: 25px -13px auto auto !important;
} */
.card-user-management .all-roles-wrapper .dropdown-menu {
  min-width: 100%;
  width: 250px;
  padding: 5px 0;
  background-color: var(--white-fill);
  inset: 0px auto auto 10px !important;
  margin-left: 0;
  transform: translate3d(-10px, 49px, 0px) !important;
}
.card-user-management .dropdown-menu li {
  margin-bottom: 0;
}
.card-user-management .dropdown-item,
.card-user-management .dropdown-item span {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.card-user-management .dropdown-item {
  padding: 8px 14px;
}
.card-user-management .dropdown-item .card-user-management .all-roles-wrapper .dropdown-item {
  padding: 0 12px;
  line-height: 2.71;
  color: var(--charcoal-grey);
}
.card-user-management .all-roles-wrapper .dropdown-item {
  background-color: transparent !important;
}
.card-user-management .dropdown-item:active,
.card-user-management .dropdown-item:active span,
.card-user-management .dropdown-item.active,
.card-user-management .dropdown-item.active span,
.card-user-management .dropdown-item:focus,
.card-user-management .dropdown-item:focus span,
.card-user-management .dropdown-item:hover,
.card-user-management .dropdown-item:hover span {
  color: var(--cerulean-blue);
  background-color: transparent;
}
.card-user-management .dropdown-item:focus {
  outline: 0 !important;
}
.card-user-management .dropdown-item span {
  display: inline-block;
  position: relative;
  top: 1px;
}
.card-user-management .dropdown-item.delete {
  color: var(--red);
}
.card-user-management .dropdown-item img {
  margin-right: 8px;
}
/* modal */
#userCreateModal {
  right: 0;
  width: 480px;
}
.modal.fade:not(.in).right .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal-header.close-button-container .close-btn {
  margin: 0;  
  float: right;  
}
/* .modal-header.close-button-container .close-btn img {
  float: left;
  width: 100%;
}
.modal-header.close-button-container .close-btn svg {
  float: left;
  width: 100%;
  height: 100%;
} */
.modal-header {
  border-bottom: none;
}
.close-button-container {
  justify-content: flex-end;
  padding: 14px 14px 4px 40px;
}
.modal-body {
  padding: 0 40px 40px 40px;
}
.user-form-head h4 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.user-form {
  max-width: 480px;
}
.create-user-form-wrap {
  margin-top: 26px;
}
.create-user-form-wrap label,
.partial-modal form label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
}
.create-user-form-wrap input,
.partial-modal
  form
  input[type="text"]:not(.partial-modal form .form-select input),
.partial-modal form input[type="number"] {
  height: 45px;
  margin: 12px 0 0;
  padding: 18px 12px 17px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  color: var(--charcoal-grey);
  font-size: 14px;
}
.create-user-form-wrap input.error,
.partial-modal form input.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
.edit-tags-form-wrap input.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
.mandatory {
  color: var(--red);
  margin-left: 2px;
}
.error {
  color: var(--red);
  font-size: 13px;
  display: block;
  line-height: 19px;
}

.role-select button {
  height: 45px;
  margin: 12px 16px 0px 0;
  padding: 11px 6px 10px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper) !important;
  background-color: var(--white-fill) !important;
  color: var(--charcoal-grey) !important;
}
.role-select .dropdown-menu li a:hover,
.role-select .dropdown-menu .dropdown-item.active,
.role-select .dropdown-menu .dropdown-item:active {
  color: var(--charcoal-grey);
  background-color: rgba(var(--peak-point-rgb), 0.1);
}
.admin-access-info {
  height: 40px;
  margin: 8px 0 33px 1px;
  padding: 6px 24px 11px 9px;
  border-radius: 6px;
  background-color: var(--pale-conflower-blue);
}
.admin-access-info img {
  margin: 0 0 0 3px;
}
.tag-wrapper input {
  padding: 18px 12px 17px 31px;
}
.admin-role-has-full {
  height: 17px;
  margin: 0 23px 0 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--chambray);
}
.invite-btn {
  position: relative;
  width: 124px;
  height: 40px;
  margin-right: 20px;
  border-radius: 6px;
  background-color: var(--booger);
  font-size: 15px;
  font-weight: bold;
  color: var(--white-fill);
}
.cancel-btn {
  width: 94px;
  height: 40px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--booger);
  font-size: 15px;
  font-weight: bold;
  color: var(--booger);
}
.invite-btn:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.invite-btn:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background-color: rgba(var(--black-rgb), 0.08);
}
.cancel-btn:hover {
  color: var(--booger);
}
.pagination-wrapper {
  float: left;
}
.pagination-wrapper ul {
  list-style: none;
  display: block;
  float: right;
  padding: 0;
  margin: 0;
}
.pagination-wrapper ul li {
  display: block;
  margin: 0 4px 0 0;
  border: 0;
  float: left;
}
.pagination-wrapper ul li:first-child {
  margin: 0 8px 0 0;
}
.pagination-wrapper ul li:last-child {
  margin: 0 0 0 8px;
}
.pagination-wrapper ul li a {
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 6px;
  border: solid 1px var(--link-water);
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 32px;
  letter-spacing: normal;
}
.pagination-wrapper ul li a:hover,
.pagination-wrapper ul li a:focus {
  padding: 0;
  background-color: var(--cerulean-blue);
  color: var(--white-fill) !important;
}
.pagination-active {
  background-color: var(--cerulean-blue);
  color: var(--white-fill) !important;
  border-radius: 6px;
}
.pagination-wrapper ul li:first-child a,
.pagination-wrapper ul li:last-child a {
  padding: 0;
}
.pagination-wrapper ul li:first-child a,
.pagination-wrapper ul li:last-child a,
.pagination-wrapper ul li:first-child a i,
.pagination-wrapper ul li:last-child a i {
  font-size: 0;
  color: transparent;
  background-repeat: no-repeat;
  background-position: center;
}
.pagination-wrapper .pagination__link--disabled a {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
  background: var(--white-fill) !important;
}
.pagination-wrapper ul li a:hover svg g g {
  fill: var(--white-fill);
}
.card-footer:last-child {
  /* background-color: var(--approx-white); */
  background-color: transparent;
  border-radius: 0;
  border-top: 0;
  margin-top: 15px;
  padding: 10px 0 20px 0;
}
.card-user-management .card-active {
  position: relative;
  padding: 0;
}
.card-user-management .card-active:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 4px;
  background-color: var(--cerulean-blue);
  left: 0;
  bottom: -11px;
}
/*user_table Data table sorting arrows customization */
#user_table.dataTable thead > tr > th.sorting:before,
#user_table.dataTable thead > tr > th.sorting_asc:before,
#user_table.dataTable thead > tr > th.sorting_desc:before,
#user_table.dataTable thead > tr > th.sorting_asc_disabled:before,
#user_table.dataTable thead > tr > th.sorting_desc_disabled:before,
#user_table.dataTable thead > tr > td.sorting:before,
#user_table.dataTable thead > tr > td.sorting_asc:before,
#user_table.dataTable thead > tr > td.sorting_desc:before,
#user_table.dataTable thead > tr > td.sorting_asc_disabled:before,
#user_table.dataTable thead > tr > td.sorting_desc_disabled:before {
  content: "" !important;
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.svg") no-repeat;
  background-position: left top;
  right: auto !important;
  bottom: 18px !important;
  top: auto !important;
  left: 58px;
}
#user_table.dataTable thead > tr > th.sorting:after,
#user_table.dataTable thead > tr > th.sorting_asc:after,
#user_table.dataTable thead > tr > th.sorting_desc:after,
#user_table.dataTable thead > tr > th.sorting_asc_disabled:after,
#user_table.dataTable thead > tr > th.sorting_desc_disabled:after,
#user_table.dataTable thead > tr > td.sorting:after,
#user_table.dataTable thead > tr > td.sorting_asc:after,
#user_table.dataTable thead > tr > td.sorting_desc:after,
#user_table.dataTable thead > tr > td.sorting_asc_disabled:after,
#user_table.dataTable thead > tr > td.sorting_desc_disabled:after {
  content: "" !important;
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.svg") no-repeat;
  background-position: right top;
  right: auto !important;
  bottom: 17px !important;
  top: auto !important;
  left: 66px;
}
#user_table.dataTable thead > tr > th.sorting:before,
#user_table.dataTable thead > tr > th.sorting:after,
#user_table.dataTable thead > tr > th.sorting_asc:before,
#user_table.dataTable thead > tr > th.sorting_asc:after,
#user_table.dataTable thead > tr > th.sorting_desc:before,
#user_table.dataTable thead > tr > th.sorting_desc:after,
#user_table.dataTable thead > tr > th.sorting_asc_disabled:before,
#user_table.dataTable thead > tr > th.sorting_asc_disabled:after,
#user_table.dataTable thead > tr > th.sorting_desc_disabled:before,
#user_table.dataTable thead > tr > th.sorting_desc_disabled:after,
#user_table.dataTable thead > tr > td.sorting:before,
#user_table.dataTable thead > tr > td.sorting:after,
#user_table.dataTable thead > tr > td.sorting_asc:before,
#user_table.dataTable thead > tr > td.sorting_asc:after,
#user_table.dataTable thead > tr > td.sorting_desc:before,
#user_table.dataTable thead > tr > td.sorting_desc:after,
#user_table.dataTable thead > tr > td.sorting_asc_disabled:before,
#user_table.dataTable thead > tr > td.sorting_asc_disabled:after,
#user_table.dataTable thead > tr > td.sorting_desc_disabled:before,
#user_table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: 1;
}
#user_table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 1;
}
#user_table.dataTable thead > tr > th.sorting_desc:before {
  opacity: 0.6;
}
#user_table.dataTable thead > tr > th.sorting_asc:after {
  opacity: 0.6;
}
#user_table.dataTable thead > tr > th.sorting_asc:before {
  opacity: 1;
}
/*Roles Dashboard*/
.green-button {
  height: 40px;
  border-radius: 6px;
  background-color: var(--booger);
  line-height: 1;
}
.green-button-text {
  font-size: 15px !important;
  font-weight: bold !important;
  line-height: normal !important;
  letter-spacing: normal;
  color: var(--white-fill) !important;
}
.card-dashboard {
  border: 0px;
  margin: 40px 0;
}
.card-dashboard .dropdown-menu {
  min-width: 130px;
  height: auto !important;
  text-align: left;
  position: absolute;
  inset: 25px -13px auto auto !important;
  margin: 0px !important;
  padding: 14px 0 14px 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  margin-left: -65px;
}
.card-dashboard .dropdown-item {
  padding: 9px 14px 9px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.card-dashboard .dropdown-item span {
  display: inline-block;
  position: relative;
  top: 1px;
}
.card-dashboard .dropdown-item.edit {
  opacity: 0.5;
}
.card-dashboard .dropdown-item.delete {
  /* opacity: 0.5; */
  color: var(--red);
}
.card-dashboard .dropdown-item:hover {
  background-color: var(--solitude);
}
.card-dashboard .dropdown-item img {
  margin-right: 8px;
}
.card-dashboard .top-action-lists .card-link {
  font-size: 20px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate);
}
.card-dashboard .card-active {
  position: relative;
  padding: 0;
  color: var(--charcoal-grey);
}
.card-dashboard .top-action-lists .card-active .card-link {
  color: var(--charcoal-grey);
  gap: 10px;
}
.card-dashboard .card-active:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 4px;
  background-color: var(--cerulean-blue);
  left: 0;
  bottom: -11px;
}
#role_table_wrapper {
  width: 100%;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
  box-shadow: 0 6px 12px 0 var(--black-rgb-percent1);
  border: solid 1px var(--warm-white);
}
.add-role-button {
  width: 118px;
}
#role_table {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-collapse: collapse !important;
}
#role_table thead,
#role_table th {
  border-top: 0 !important;
}
#role_table th,
#role_table td {
  padding: 13px 24px !important;
  padding-left: 0 !important;
}
#role_table th,
#role_table th span {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
}
#role_table td {
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  box-shadow: none;
}
#role_table td:first-child {
  width: 24% !important;
  line-height: 1.71;
  padding-left: 24px !important;
}
#role_table td:nth-child(2) {
  line-height: 1.43;
  width: calc(76%-43px) !important;
}
#role_table td:nth-child(2) p {
  max-width: 600px;
  line-height: 1.43;
}
#role_table th:first-child {
  padding-left: 24px !important;
  border-radius: 12px 0 0 0;
}
#role_table th:last-child {
  border-radius: 0 12px 0 0;
  text-align: right;
}
#role_table tr th:last-child,
#role_table tr td:last-child {
  width: 43px !important;
}
#role_table tr:last-child td:first-child {
  border-radius: 0 0 0 12px;
}
#role_table tr:last-child td:last-child {
  border-radius: 0 0 12px 0;
}
#role_table th span {
  display: inline-block;
  margin-right: -4px;
}
#role_table th[data-orderable="true"] {
  cursor: pointer;
}
#role_table th[data-orderable="true"] span {
  position: relative;
  padding-right: 24px;
}
#role_table th[data-orderable="true"] span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: left top;
  bottom: 4px;
  right: 8px;
}
#role_table th[data-orderable="true"] span:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: right top;
  right: 0;
  bottom: 4px;
}
#role_table th.ascending span:before {
  opacity: 1;
}
#role_table th.ascending span:after {
  opacity: 0.5;
}
#role_table th.descending span:before {
  opacity: 0.5;
}
#role_table th.descending span:after {
  opacity: 1;
}
/*user_table Data table sorting arrows customization */
#role_table.dataTable thead > tr > th.sorting:before,
#role_table.dataTable thead > tr > th.sorting_asc:before,
#role_table.dataTable thead > tr > th.sorting_desc:before,
#role_table.dataTable thead > tr > th.sorting_asc_disabled:before,
#role_table.dataTable thead > tr > th.sorting_desc_disabled:before,
#role_table.dataTable thead > tr > td.sorting:before,
#role_table.dataTable thead > tr > td.sorting_asc:before,
#role_table.dataTable thead > tr > td.sorting_desc:before,
#role_table.dataTable thead > tr > td.sorting_asc_disabled:before,
#role_table.dataTable thead > tr > td.sorting_desc_disabled:before {
  content: "" !important;
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.svg") no-repeat;
  background-position: left top;
  right: auto !important;
  bottom: 17px !important;
  top: auto !important;
  left: 60px;
}
#role_table.dataTable thead > tr > th.sorting:after,
#role_table.dataTable thead > tr > th.sorting_asc:after,
#role_table.dataTable thead > tr > th.sorting_desc:after,
#role_table.dataTable thead > tr > th.sorting_asc_disabled:after,
#role_table.dataTable thead > tr > th.sorting_desc_disabled:after,
#role_table.dataTable thead > tr > td.sorting:after,
#role_table.dataTable thead > tr > td.sorting_asc:after,
#role_table.dataTable thead > tr > td.sorting_desc:after,
#role_table.dataTable thead > tr > td.sorting_asc_disabled:after,
#role_table.dataTable thead > tr > td.sorting_desc_disabled:after {
  content: "" !important;
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.svg") no-repeat;
  background-position: right top;
  right: auto !important;
  bottom: 16px !important;
  top: auto !important;
  left: 68px;
}
#role_table.dataTable thead > tr > th.sorting:before,
#role_table.dataTable thead > tr > th.sorting:after,
#role_table.dataTable thead > tr > th.sorting_asc:before,
#role_table.dataTable thead > tr > th.sorting_asc:after,
#role_table.dataTable thead > tr > th.sorting_desc:before,
#role_table.dataTable thead > tr > th.sorting_desc:after,
#role_table.dataTable thead > tr > th.sorting_asc_disabled:before,
#role_table.dataTable thead > tr > th.sorting_asc_disabled:after,
#role_table.dataTable thead > tr > th.sorting_desc_disabled:before,
#role_table.dataTable thead > tr > th.sorting_desc_disabled:after,
#role_table.dataTable thead > tr > td.sorting:before,
#role_table.dataTable thead > tr > td.sorting:after,
#role_table.dataTable thead > tr > td.sorting_asc:before,
#role_table.dataTable thead > tr > td.sorting_asc:after,
#role_table.dataTable thead > tr > td.sorting_desc:before,
#role_table.dataTable thead > tr > td.sorting_desc:after,
#role_table.dataTable thead > tr > td.sorting_asc_disabled:before,
#role_table.dataTable thead > tr > td.sorting_asc_disabled:after,
#role_table.dataTable thead > tr > td.sorting_desc_disabled:before,
#role_table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: 1;
}
#role_table.dataTable thead > tr > th.sorting_desc:after {
  opacity: 1;
}
#role_table.dataTable thead > tr > th.sorting_desc:before {
  opacity: 0.6;
}
#role_table.dataTable thead > tr > th.sorting_asc:after {
  opacity: 0.6;
}
#role_table.dataTable thead > tr > th.sorting_asc:before {
  opacity: 1;
}
/* Tags dropdown */
.multiSelectContainer .searchWrapper {
  padding: 8px 34px 8px 11px !important;
  position: relative;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-size: 24px 24px;
}
.multiSelectContainer .searchWrapper:after {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  background: url("../../../assets/images/dropdwon-arrow-down.svg") no-repeat
    center;
  background-size: 24px 24px;
  right: 6px;
  top: 50%;
  margin-top: -12px;
  border: 0 !important;
}
.multiSelectContainer .searchWrapper:focus {
  border: solid 1px var(--cerulean-blue);
}
.multiSelectContainer .searchBox {
  width: 100px !important;
  padding: 0 13px 0 12px !important;
  height: 30px !important;

  font-size: 14px !important;
  font-weight: normal !important;

  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: rgba(var(--charcoal-grey-rgb), 0.97) !important;
  margin: 0 !important;
}
.optionListContainer {
  margin-top: 8px !important;
}
.highlightOption {
  background-color: transparent !important;
}
.multiSelectContainer li.highlightOption:hover,
.multiSelectContainer li:hover {
  background: var(--whisper) !important;
  color: var(--charcoal-grey) !important;
}
.multiSelectContainer ul {
  list-style: none;
  padding: 0 !important;
  max-height: 150px !important;
  border-radius: 6px !important;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1);
  box-shadow: 0 2px 6px 0 var(--black-shadow1);
  border: solid 1px var(--new-whisper) !important;
  background-color: var(--white-fill);
}
.multiSelectContainer li {
  width: 100%;
  position: relative;
  padding: 12px 12px 12px 36px !important;
  float: left;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.multiSelectContainer .notFound {
  display: block;
  font-size: 14px;
  font-weight: normal !important;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.5);
  padding: 15px 12px 31px 12px;
}
.multiSelectContainer input[type="checkbox"] {
  margin: 0;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: solid 1px var(--slate);
  background-color: var(--white-fill);
  padding: 0;
  left: 12px;
  top: 12px;
}
.multiSelectContainer input[type="checkbox"]:checked {
  background-color: var(--cerulean-blue);
  background-image: url("../../../assets/images/checkbox_check.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 7px 6px;
}
.chip {
  padding: 2px 4px 2px 8px !important;
  border-radius: 4px !important;
  border: solid 1px var(--pattens-blue) !important;
  background-color: var(--approx-alice-blue) !important;
  font-size: 13px !important;
  font-weight: normal;
  line-height: 1.54;
  letter-spacing: normal;
  color: var(--battleship-grey) !important;
  margin-right: 8px !important;
}
.custom-close {
  margin-left: 8px;
  cursor: pointer;
}
/* Tags */
.align-wrapper {
  display: inline-block;
  width: 100%;
  margin-right: -4px;
}
.align-wrapper label {
  float: left;
}
.manage-tags-link {
  position: relative;
  display: block;
  float: right;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--cerulean-blue);
  text-decoration: none;
  position: relative;
  padding-left: 20px;
  margin-top: 6px;
}
.manage-tags-link:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 0;
  object-fit: contain;
  background: url("../../../assets/images/settings-icon-blue-bg.svg") no-repeat
    center;
  background-size: 14px 14px;
  left: 0;
  top: -3px;
}
#manageTagsModal {
  right: 0;
  width: 480px;
}
.hidden {
  display: none;
}
#manageTagsModal form {
  width: 100%;
  float: left;
  clear: both;
}
.title {
  display: block;
  width: 100%;
}
#manageTagsModal .title {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey);
}
.action-buttons .edit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 0 0 22px;
  background: url("../../../assets/images/edit_icon.svg") transparent no-repeat
    left center;
  background-size: 16px 16px;
  border: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
element.style {
  display: block;
}
#userCreateModal {
  right: 0;
  width: 480px;
}
.custom-modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1326;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.custom-modal.show {
  display: block;
}
.custom-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1321;
  width: 100vw;
  height: 100vh;
  background-color: var(--black);
  display: none;
}
.custom-backdrop.fade {
  opacity: 0;
}
.custom-backdrop.show {
  display: block;
  opacity: 0.2;
}
.secondary-backdrop.show {
  opacity: 0;
}
.edit-tags-form-wrap .row {
  border-bottom: solid 1px var(--new-whisper);
}
.edit-tags-form-wrap .title-row {
  border-top: solid 1px var(--new-whisper);
}
.edit-tags-form-wrap .col {
  padding-top: 12px;
  padding-bottom: 10px;
}
.edit-tags-form-wrap input,
.add-tag-form-wrap input,
.edit-tags-form-wrap .tagName {
  width: 80px;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 6px !important;
  border-radius: 4px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  pointer-events: auto !important;
  margin: 0 !important;
  height: 30px !important;
}
.edit-tags-form-wrap input {
  display: block;
}
.edit-tags-form-wrap input:disabled {
  border: 0;
  background: transparent;
  opacity: 1;
  pointer-events: none !important;
  padding-left: 0;
  display: none;
}
.edit-tags-form-wrap .tagName {
  display: block;
  width: 100% !important;
  float: left;
  border-radius: 0;
  border: 0;
  padding: 6px 0 !important;
  margin: 5px 0 !important;
  background: transparent;
}
.edit-tags-form-wrap .tagName.hidden {
  display: none !important;
}

.edit-tags-form-wrap .row.editing {
  background-color: var(--solitude);
}
.edit-tags-form-wrap .row:not([class^="title-row"]):hover {
  background-color: var(--solitude);
}
.add-tag-form-wrap {
  width: 100%;
}
.add-tag-form-wrap input {
  width: 80px;
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 6px;
  border-radius: 4px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
}
.tags-container .action-buttons .save,
.tags-container .action-buttons .cancel,
.tags-container .action-buttons .save-add,
.tags-container .action-buttons .cancel-add {
  position: relative;
  top: 5px !important;
}
.add-new {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
  margin-bottom: 19px;
  margin-left: 40px;
  margin-top: 19px;
  float: left;
  clear: both;
  cursor: pointer;
  background-image: url("../../../assets/images/add-tag-icon.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 5px 10px;
  padding-left: 28px;
}
.custom-modal .back-button {
  position: relative;
  width: 24px;
  height: 24px;  
  margin-right: 8px;  
  padding: 0;
  top: 4px;
  background: transparent;
  cursor: pointer;
}

.custom-modal h4 {
  float: left;
  max-width: calc(100% - 32px);
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  text-align: left;
  padding-left: 0;
  margin: 0;
}
.custom-modal .close-button-container {
  padding-bottom: 4px;
}
.custom-modal .modal-body {
  padding: 0 0 20px 0;
}
.custom-modal .modal-body .row .col:first-child {
  padding-left: 40px;
  padding-right: 10px;
}
.custom-modal .modal-body .row .col:last-child {
  padding-right: 40px;
  padding-left: 10px;
}
.custom-modal .user-form-head {
  padding-left: 36px;
  padding-right: 40px;
}
.modal-header {
  border-bottom: none;
}
.Toastify__toast-theme--light {
  width: 400px !important;
  min-height: 45px !important;
  border-radius: 6px !important;
  background-color: var(--granny-apple);
  color: var(--deep-green) !important;
  font-size: 14px;
}
.Toastify__toast-container--top-right {
  top: 2.5em !important;
  right: 22.3em !important;
}
.Toastify__progress-bar--success {
  background-color: rgba(var(--black-rgb), 0.15) !important;
}
.Toastify__close-button--light {
  color: #d32f2f !important;
  opacity: 0.7 !important;
  align-self: center !important;
  margin-right: 4px !important;
}
.Toastify__close-button--light:hover {
  color: #d32f2f !important;
  opacity: 1 !important;
  align-self: center !important;
  margin-right: 4px !important;
}
.Toastify__toast-icon {
  width: 24px !important;
}
.toast-error.Toastify__toast-theme--light {
  width: 400px !important;
  min-height: 45px !important;
  border-radius: 6px !important;
  background-color: var(--pink) !important;
  color: var(--red) !important;
  font-size: 14px;
}
.toast-error.Toastify__toast-container--top-right {
  top: 2.5em !important;
  right: 22.3em !important;
}
.toast-error.Toastify__progress-bar--error {
  background-color: rgba(var(--black-rgb), 0, 0, 0, 0.15) !important;
}
.toast-info {
  color: var(--chambray) !important;
  border-radius: 6px;
  background-color: var(--pale-conflower-blue) !important;
}
.toast-warn {
  border-radius: 6px;
  background-color: var(--pale-conflower-blue) !important;
}
.Toastify__progress-bar--info {
  background-color: rgba(var(--black-rgb), 0.15) !important;
}
.locked {
  position: relative;
}
.locked:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: -22px;
  margin-top: -8px;
  background: url("../../../assets/images/lock-icon.svg") no-repeat center;
  background-size: 16px 16px;
}
/*Delete Modal */
.delete-modal {
  width: 500px;
}

/* save button disable code */
.create-user-form-section-disable,
.user-tag-form-section-disable {
  opacity: 0.5;
  pointer-events: none;
}
.org-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.organization-wrap .form-outline {
  flex-basis: 48%;
  width: 48% !important;
}
.organization-wrap h5 {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 20px;
}
.organization-wrap hr {
  width: 100%;
  height: 1px;
  background-color: var(--approx-whisper);
  margin: 30px 0;
}
.org-dropdown {
  position: relative;
}
.org-dropdown:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border: 0 !important;
  right: 14px;
  top: 50%;
  margin-top: -5px;
  background: url("../../../assets/images/dropdwon-arrow-down.svg") no-repeat
    center;
  background-size: 24px 24px;
}
.add-more span {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.18px;
  color: var(--cerulean-blue);
}
.add-more a {
  text-decoration: none;
}
.org-flex-wrap .MuiAutocomplete-endAdornment {
  top: 21px;
}
.org-flex-wrap button.MuiAutocomplete-popupIndicator {
  cursor: default !important;
}
.org-flex-wrap button.MuiAutocomplete-popupIndicator .MuiTouchRipple-root {
  display: none !important;
}
.org-flex-wrap .MuiAutocomplete-popupIndicator:hover {
  background-color: transparent !important;
}
.org-option {
  padding: 12px;
}
.org-option:not(:last-child) {
  border-bottom: 1px solid var(--approx-whisper);
}
.org-label {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey);
  clear: both;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.org-desc {
  width: 100%;
  font-size: 12px;
  letter-spacing: 0.24px;
  color: var(--battleship-grey);
  clear: both;
}
.org-flex-wrap .MuiAutocomplete-inputRoot {
  padding: 0 !important;
}
.org-flex-wrap input {
  box-sizing: border-box;
}
.MuiAutocomplete-popper {
  padding: 0 !important;
  margin-top: 4px !important;
}
.MuiAutocomplete-noOptions {
  display: block;
  font-size: 14px;
  font-weight: normal !important;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.5);
  padding: 15px 12px 31px 12px;
}
.org-flex-wrap .filter-option-inner-inner {
  font-size: 14px;
}
.org-flex-wrap .MuiAutocomplete-input {
  padding-right: 32px !important;
}
.more-link,
.more-link-btn {
  display: inline-block;
  font-size: 14px;
  line-height: 1.71;
  color: var(--cerulean-blue) !important;
  cursor: pointer;
  text-decoration: none;
}
.popover-org {
  font-size: 14px;
  line-height: 0.71;
  color: var(--charcoal-grey);
}
.popover-org p:last-of-type {
  margin-bottom: 0;
}
.popover-org p {
  line-height: normal;
  word-break: break-word;
}
.role-select.form-select {
  margin-top: 12px;
  float: left;
}
.role-select.form-select input {
  height: auto !important;
}
.role-select.form-select .css-1s2u09g-control,
.role-select.form-select .css-1pahdxg-control {
  height: 45px;
}
.bootstrap-select .dropdown-menu li {
  position: static !important;
}
.invite-btn.save-butn {
  width: 80px;
}
.edit-payment-term-form-wrap input {
  height: 30px !important;
  padding: 6px !important;
  margin: 0 !important;
}
.edit-payment-term-form-wrap .action-buttons .save,
.edit-payment-term-form-wrap .action-buttons .cancel,
.edit-payment-term-form-wrap .action-buttons .save-add,
.edit-payment-term-form-wrap .action-buttons .cancel-add {
  position: relative;
  top: 2px !important;
}
.toast-custom-style {
  white-space: pre-line !important;
  line-height: 19px;
}
.toast-copy-button {
  background-color: transparent;
  border: none;
  color: black;
  padding: 2px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
}
.toast-copy-button .MuiSvgIcon-root {
  width: 1.25em !important;
  height: 1.25em !important;
  color: var(--red);
  opacity: 0.7;
  margin-top: -4px;
}
.toast-error-copy {
  color: var(--red);
  font-size: 12px;
  opacity: 1;
}
.user-name-with-pending {
  display: flex;
  gap: 25px;
}
.user-name-with-pending .invite-pending {
  width: 48px;
  height: 24px;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.5px;
  color: #b47300;
}
.pending-rectangle {
  width: auto;
  height: 24px;
  padding: 0 6px;
  border-radius: 4px;
  background-color: #ffdb6a;
  margin-top: -2px;
}
.user-side-modal-wrapper .reusable-modal-btn{
  justify-content: flex-start !important;
}
/** Media Queries **/
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .card-user-management .top-action-lists {
    float: left !important;
    clear: both !important;
  }
  .main-card .button-list.top-action-lists {
    margin-top: 16px !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (max-width: 767px) {

}
@media (max-width: 420px) {
  .Toastify__toast-container--top-center {
    width: 100%;
  }
  .toast-error.Toastify__toast-theme--light {
    width: calc(100% - 10px) !important;
    margin-right: auto;
    margin-left: auto;
  }
}
