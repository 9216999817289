.configure-proration-setting-btn {
    width: 200px !important;
  }
.configure-proration-setting-btn:hover {
    color: white !important;
  }
.configure-proration-setting-btn:hover svg path {
    fill: white !important;
  }

#pills-tabContent {
  padding-top: 14px;
}

.m-r-9 {
 margin-right: 9px;
}

.m-t-18 .css-fsj7ww-MuiPaper-root-MuiCard-root{
  margin-top: 18px;
}

.plan-Specific-list-table .list-table {
  min-width: 900px;
}

.plan-Specific-list-table .add-on-table-container {
  position: relative;
  overflow: visible;
}