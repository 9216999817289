.reportingtag-jv .table-section table.inv-preview-table, #invoice .table-section table.bills-preview-table, #invoice .table-section table.cnote-preview-table, #invoice .table-section table.dnote-preview-table {
    width: 100%;
    min-width: 1054px;
    margin-bottom: 0;
}

.reportingtag-paper{
    z-index:1200000000!important;
}
.report-tags-table {
    min-width: auto;
}
.report-tags-table td span.account_name,
.report-tags-table td .select-reporting-tag-modal {
    display: inline-block;
}
.report-tags-table td span.account_name{
    max-width: 320px;
}
.report-tags-table td .select-reporting-tag-modal {    
    margin-left: 0;    
}
.report-tags-table .select-reporting-tag-modal .open-reporting-tag-modal-btn {
    position: relative;
    top: -3px;
}
.report-tags-table tr th:nth-child(2),
.report-tags-table tr th:nth-child(3),
.report-tags-table tr td:nth-child(2),
.report-tags-table tr td:nth-child(3)  {
    text-align: right;
}
.report-tags-table tr th
.report-tags-table tr td {
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.report-tags-table tr th:first-child
.report-tags-table tr td:first-child {
    padding-left: 24px !important;
}
.report-tags-table tr th:last-child
.report-tags-table tr td:last-child {    
    padding-right: 24px !important;
}
.reporting-tag-footer-btn-grp{
    position: fixed;
    bottom: 0;
    background: #ffffff;
    padding: 24px 0px;
}