.center-modal-content {
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: calc(0.7rem - 1px);
  position: absolute;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}
.resusable-center-modal {
  box-shadow: none;
  background-color: white;
}
.modal-close-btn {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  padding: 10px;
  z-index: 1000;
  cursor: pointer;
}
.center-modal-title {
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  padding: 30px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.reusable-modal-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.center-modal-title h5 {
  color: var(--charcoal-grey);
  font-size: 24px;
}
.central-modal.large-form-modal h5 {
  font-size: 24px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 0;
}

.central-modal.large-form-modal label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  margin-bottom: 12px;
  float: left;
}

.central-modal.large-form-modal .row {
  margin-bottom: 20px;
  clear: both;
}

.central-modal.large-form-modal .form-control {
  max-width: 325px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);

  font-size: 14px;
  font-weight: normal;

  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.central-modal.large-form-modal .modal-header {
  padding: 30px 30px 24px 40px;
}

.central-modal.large-form-modal .modal-body {
  padding: 0;
}

.central-modal.large-form-modal .modal-content {
  border-radius: 12px;
  max-height: 92vh;
  width: inherit;
  border-radius: 12px;
}
.central-modal.large-form-modal .modal-header {
  border-radius: 12px 12px 0 0;
}
.central-modal.large-form-modal
  .add-consumer-form
  .form-button-wrapper.docked-button-container,
.central-modal.large-form-modal
  .add-consumer-form#add_customer_form
  .form-button-wrapper.docked-button-container,
.central-modal.large-form-modal
  .add-consumer-form#add_vendor_form
  .form-button-wrapper.docked-button-container {
  border-radius: 0 0 12px 12px;
}

.central-modal.large-form-modal .modal-dialog {
  max-width: 870px;
  width: 100%;
  max-height: 92vh;
}

.central-modal.large-form-modal .form-top-section {
  padding: 0 40px;
  border-bottom: 0;
}

.central-modal.large-form-modal .form-tab-section {
  display: inline-block;
  width: 100%;
  padding: 24px 0;
  /* padding-top: 24px; */
}

.central-modal.large-form-modal .pills-tab {
  width: 100%;
  margin: 12px 0 0 0;
  padding: 7px 50px 6px;
  background-color: rgba(var(--peak-point-rgb), 0.07);
}

.central-modal.large-form-modal .tab-content {
  width: 100%;
  padding: 24px 50px;
}
.central-modal.large-form-modal .tab-content .address-tab-pane .mw-545 {
  max-width: 270px !important;
}
.central-modal.large-form-modal
  .add-consumer-form
  .form-button-wrapper.docked-button-container,
.central-modal.large-form-modal
  .add-consumer-form#add_customer_form
  .form-button-wrapper.docked-button-container,
.central-modal.large-form-modal
  .add-consumer-form#add_vendor_form
  .form-button-wrapper.docked-button-container {
  position: static !important;
  padding-left: 50px !important;
  width: 100% !important;
  margin-top: 0 !important;
}

.central-modal.large-form-modal .tab-content .address-tab-pane .mw-545 {
  max-width: 270px !important;
}

.central-modal.large-form-modal .add-consumer-form .custom-row .mw-325 {
  max-width: 325px !important;
}
.central-modal.large-form-modal .add-consumer-form .form-button-wrapper {
  padding: 24px 50px;
}

.central-modal.large-form-modal .fields-wrapper {
  border: none;
  height: auto;
  max-height: calc(82vh - 77px);
  width: 100%;
  float: left;
  overflow: hidden;
  overflow-y: auto;
}

.central-modal.large-form-modal .billing-address.mw-545 {
  max-width: 272px !important;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .address-wrapper {
  flex-direction: column !important;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .address-wrapper
  .address-fields-wrapper
  .form-select,
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .address-wrapper
  .address-fields-wrapper
  .form-control {
  width: 100% !important;
  max-width: none !important;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .address-wrapper
  .address-fields-wrapper
  .col {
  clear: both !important;
  width: 100% !important;
  max-width: 330px !important;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .address-wrapper {
  flex: auto !important;
  width: auto !important;
}

.central-modal.large-form-modal
  .form-tab-section
  .address-tab-pane
  .billing-address:after {
  display: none;
  top: 43px !important;
  height: calc(100% - 62px);
}

.central-modal.large-form-modal .address-tab-pane .billing-address,
.central-modal.large-form-modal .address-tab-pane .shipping-address {
  display: flex;
  flex-direction: column;
}
.central-modal.large-form-modal .address-tab-pane .billing-address,
.central-modal.large-form-modal .address-tab-pane .shipping-address {
  width: 100% !important;
  flex: 0 !important;
}

.central-modal.large-form-modal .shipping-address h2 {
  padding-left: 0 !important;
}
.central-modal.large-form-modal #pills-address .address-container {
  justify-content: flex-start;
}
.central-modal.large-form-modal
  .address-tab-pane
  .address-container-wrapper:after {
  display: none;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .billing-address:after {
  display: block;
  top: 0 !important;
  height: 100% !important;
}
.central-modal.large-form-modal .address-tab-pane .billing-address {
  margin-bottom: 20px !important;
}
.central-modal.large-form-modal .address-tab-pane .shipping-address {
  position: relative;
}
.central-modal.large-form-modal .address-tab-pane .shipping-address:before {
  border-top: 1px solid var(--approx-whisper);
  content: "";
  height: 1px;
  position: absolute;
  right: auto;
  left: 0;
  top: -14px;
  width: 100%;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .shipping-address:before {
  display: none !important;
}
.central-modal.large-form-modal .boxed-item.address-item {
  margin: 0 16px 16px 0 !important;
}

.central-modal.large-form-modal .address-tab-pane .address-container-wrapper {
  width: 100%;
  clear: both;
  height: 100%;
  position: relative;
}
.central-modal.large-form-modal .address-tab-pane .billing-address:after {
  top: auto;
  bottom: 0;
}
.central-modal.large-form-modal .shipping-address h2 {
  padding-left: 0 !important;
}
.central-modal.large-form-modal #pills-address .address-container {
  justify-content: flex-start;
}
.central-modal.large-form-modal
  .address-tab-pane
  .address-container-wrapper:after {
  display: none;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .billing-address:after {
  display: block;
  top: 0 !important;
  height: 100% !important;
}
.central-modal.large-form-modal .address-tab-pane .billing-address {
  margin-bottom: 20px !important;
}
.central-modal.large-form-modal .address-tab-pane .shipping-address {
  position: relative;
}
.central-modal.large-form-modal .address-tab-pane .billing-address,
.central-modal.large-form-modal .address-tab-pane .shipping-address {
  flex: 0 0 auto !important;
  width: 100% !important;
}
.central-modal.large-form-modal .address-tab-pane .shipping-address:before {
  border-top: 1px solid var(--approx-whisper);
  content: "";
  height: 1px;
  position: absolute;
  right: auto;
  left: 0;
  top: -14px;
  width: 100%;
}
.central-modal.large-form-modal
  .add_vendor_form
  .address-tab-pane
  .shipping-address:before {
  display: none !important;
}
.central-modal.large-form-modal .boxed-item.address-item {
  margin: 0 16px 16px 0 !important;
}
.central-modal.large-form-modal .tab-content > .tab-pane {
  width: 100%;
  float: left;
}

.medium-form-modal .modal-dialog {
  max-width: 633px;
}

.medium-form-modal .modal-header {
  padding: 30px 30px 15px 30px;
}
.medium-form-modal .modal-content {
  width: 633px;
  border-radius: 12px;
  -webkit-box-shadow: 0 2px 6px 0 var(---black-shadow3);
  box-shadow: 0 8px 20px 0 var(---black-shadow3);
  border: solid 1px var(--warmWhite);
  background-color: var(--white-fill);
}
.medium-form-modal .modal-header h1 {
  font-size: 24px;
  font-weight: 900;

  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin: 0;
}
.medium-form-modal .modal-body {
  padding: 0;
}
.medium-form-modal .modal-body .org-settings-form,
.medium-form-modal .modal-body .fields-wrapper {
  border: 0;
  box-shadow: none;
  border-radius: 0;
  padding: 0 !important;
}
.medium-form-modal .modal-body .org-settings-form {
  border-radius: 12px !important;
}
.medium-form-modal .modal-body .fields-wrapper {
  max-height: 66vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 30px !important;
}
.medium-form-modal .image-upload-container h5 {
  display: none !important;
}
.medium-form-modal .form-button-section:before {
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
  top: 0;
}
.medium-form-modal .fields-wrapper .image-upload-container {
  min-height: 104px !important;
}
.medium-form-modal .form-logo-section {
  padding: 10px 0 0 0 !important;
  border-bottom: 0 !important;
}
.medium-form-modal .img-upload-wrapper {
  padding-top: 5px;
}
.medium-form-modal .img-upload-wrapper .preview-block {
  width: 100px;
  flex: 0 0 100px;
}
.medium-form-modal .preview-block .img-container {
  width: 100px;
  height: 100px;
}
.medium-form-modal .img-upload-wrapper .preview-block a {
  font-size: 12px;
}
.medium-form-modal .upload-block {
  width: calc(100% - 100px);
  padding-left: 22px;
}
.medium-form-modal .img-upload-wrapper.edit-wrapper .upload-block {
  padding: 38px 0 38px 22px;
}
.medium-form-modal .form-fields-section {
  padding: 10px 0 2px 0;
}
.medium-form-modal .form-button-section {
  padding: 20px 30px;
  border-radius: 0 0 12px 12px;
}
.medium-form-modal .fields-wrapper .form-fields .row {
  margin-bottom: 18px;
}
.medium-form-modal #address {
  max-width: none !important;
  clear: both;
}
.medium-form-modal .org-settings-form .fields-wrapper .form-fields,
.medium-form-modal .org-settings-form .mw-500,
.medium-form-modal .org-settings-form .alert-msg {
  max-width: none !important;
}
.org-profile-modal .reusable-modal-btn {
  display: inline-block !important;
}
/** Media queries **/
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 768px) and (max-width: 991px) {
  .central-modal.large-form-modal .row {
    margin-bottom: 0;
  }
  .central-modal.large-form-modal .modal-dialog {
    max-width: 720px !important;
  }
  .central-modal.large-form-modal
    .add_vendor_form
    .address-tab-pane
    .billing-address:after {
    display: none !important;
  }
  .central-modal.large-form-modal
    .add_vendor_form
    .address-tab-pane
    .shipping-address:before {
    display: block !important;
  }
  .central-modal.large-form-modal
    .add_vendor_form
    .address-tab-pane
    .address-wrapper {
    width: 100% !important;
    max-width: none !important;
  }
  .central-modal.large-form-modal
    .add_vendor_form
    .address-tab-pane
    .billing-address:after {
    display: none !important;
  }
  .central-modal.large-form-modal
    .add_vendor_form
    .address-tab-pane
    .shipping-address:before {
    display: block !important;
  }
  .central-modal.large-form-modal
    .add_vendor_form
    .address-tab-pane
    .address-wrapper {
    width: 100% !important;
    max-width: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
}
@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (max-width: 1040px) {
}
@media (min-width: 1025px) and (max-width: 1040px) {
}
