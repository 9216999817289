/* .select-reporting-tag-modal {
    width: 740px;
} */
.select-reporting-tag-wrapper h3{
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    color: var(--charcoal-grey);
    margin-bottom: 15px;
}
.select-reporting-tag-wrapper h3 .small-text {
    display: inline-flex;
    font-size: 14px;
}
.open-reporting-tag-modal-btn {
    border: 0!important;
    background-color: transparent!important;
    box-shadow: none !important;
    color: var(--cerulean-blue)!important;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 1.71;
    text-decoration: none;
    padding: 0 !important;
    margin-top: 5px;
}
.open-reporting-tag-modal-btn img {
    position: relative;
    width: 15px;
    height: auto;
    margin-right: 5px;
    top: -2px;
}
.select-reporting-tag-drwaer#sidemodal-content {
    height: 100%;
    padding-bottom: 118px;
}