/*  */
.reporting-tags-form-container {
  padding: 10px 40px 0px 40px;
  width: 450px;
}
.text-btn-wrapper {
  margin-top: 10px;
}
.reporting-tags-options-wrapper {
  display: flex;
  flex-direction: column;
}
.reporting-tags-options-map-container {
  max-height: calc(100vh - 312px);
  overflow-y: auto;
}
.reporting-tag-list-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ellipsis-options-string {
  display: block;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reporting-tags-form-wrapper {
  width: 480px;
}
.reporting-tags-options-label-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}
.add-reporting-tag-options-btn {
  margin-right: 10px;
}
.reporting-tag-edit-modal .resuable-modal-btn {
  display: flex !important;
  justify-content: flex-start !important;
}
.reporting-tag-settings-form-wrapper {
  padding: 20px 40px;
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.reporting-tags-settings-form-radio-btn {
  display: flex;
  flex-direction: column;
}
.reporting-tags-settings-form-radio-btn label {
  margin-bottom: 7px !important;
}
.configure-reporting-tag-setting-btn {
  width: 250px;
  margin-right: 30px;
}
.configure-reporting-tag-setting-btn:hover {
  color: white !important;
}
.configure-reporting-tag-setting-btn:hover svg path {
  fill: white !important;
}
.reporting-tags-form-container .MuiTypography-root {
  color: var(--charcoal-grey) !important;
  font-family: "Lato", sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.reporting-tags-settings-form-checkbox-wrapper {
  margin-bottom: 30px;
}
.reporting-tags-settings-form-checkbox-wrapper .MuiFormControlLabel-root {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.reporting-tag-modal {
  height: 100%;
  padding-bottom: 118px;
}
@media (max-width: 1024px) {
  .reporting-tag-list-header {
    flex-direction: column !important;
  }
  .reporting-tag-list-header .reusable-card-header-actions {
    margin-top: 20px;
  }
}
