.sales-purchase-discount-select {
  width: 100% !important;
}
.discount-input {
  height: 40px;
  border-radius: 6px !important;
  border: solid 1px var(--new-whisper) !important;
  background: var(--white-fill) !important;
  position: relative !important;
  padding: 15px 12px 14px 12px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  text-align: left !important;
  margin: 0 !important;
}
.label-wrapper .discount {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.discount-info-icon {
  line-height: 30px !important;
  top: 0 !important;
}
.discount-type-wrapper {
  width: fit-content !important;
  height: 25px !important;
}
.discount-type-wrapper button {
  height: 23px !important;
}
.discount-selector.accounts-dropdown.dropdown-menu {
  min-width: 100% !important;
}
.sales-purchase-discount-select.accounts-dropdown .dropdown-menu {
  min-width: 100% !important;
}
.discount-selector.error {
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
  border-radius: 5px !important;
}
.discount-selector input {
  font-size: 14px !important;
}
.sales-purchase-discount-select.accounts-dropdown
  .dropdown-menu:not(.accounts-dropdown.drop-up .dropdown-menu) {
  inset: auto auto 0px 0px !important; 
  margin: 0px !important;
  transform: translate(0px, -42px) !important;
  min-width: 100% !important;
}
