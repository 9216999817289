.subscription-invoice-details .plan-description {
  padding: 22px;
  border-bottom: 1px solid #e9e9e9;
}

.subscription-invoice-details .item-bold {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 2px;
}

.subscription-invoice-details .plan-tag {
  letter-spacing: normal;
  color: var(--battleship-grey);
  font-size: 12.5px;
}


.invoice-preview-subscription-body
  .subscription-data-view-table
  tr
  th:last-child {
  align-items: end;
}

.invoice-preview-subscription-body
  .subscription-data-view-table
  tr
  th:not(:last-child),
.invoice-preview-subscription-body
  .subscription-data-view-table
  tr
  td:not(:last-child),
.invoice-preview-subscription-body
  .subscription-data-view-table
  .text-align-left {
  /* text-align: right !important; */
  text-align: left !important;
}

/* .invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table thead th:nth-child(3),
.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table tr td:nth-child(3),
.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table thead th:nth-child(7),
.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table tr td:nth-child(7)
 {
  text-align: right !important;
} */

.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table thead th:nth-child(6),
.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table tr td:nth-child(6)
 {
  text-align: left !important;
}

.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table .description {
  min-width: 141px !important;
}

.invoice-preview-body-sub-main-cont #invoice .table-section .table.inv-preview-table .acnt {
  text-align: left !important;
}

.invoice-preview-body-sub-main-cont #invoice .table-section table.inv-preview-table thead th:nth-child(2),
.invoice-preview-body-sub-main-cont #invoice .table-section table.inv-preview-table tr td:nth-child(2) {
  width: auto !important;
}

.invoice-preview-subscription-body
  .subscription-details-plan-addon-coupon-heading-invoice {
  font-size: 18px;
  line-height: normal;
  letter-spacing: -0.23px;
  font-weight: 900;
  padding-bottom: 15px;
}

.invoice-preview-body-sub-main-cont #invoice .table-section .left-align .amt {
  text-align: left !important;
}