.accounts-dropdown .dropdown-menu {
  min-width: 100% !important;
  z-index: 10;
}

.accounts-dropdown .search-dropdown {
  width: 160px !important;
  height: 40px;
  border-radius: 6px !important;
  border: solid 1px var(--new-whisper) !important;
  background: var(--white-fill) !important;
  position: relative !important;
  padding: 15px 31px 14px 12px !important;

  font-size: 14px !important;
  font-weight: normal !important;

  line-height: 0.71 !important;
  letter-spacing: normal !important;
  color: var(--charcoal-grey) !important;
  text-align: left !important;
  background: var(--white-fill)
    url("../../../../assets/images/arrow-drop-right.svg") no-repeat right 4px
    center !important;
  background-size: 20px 20px;
}
.accounts-dropdown .search-dropdown.full-width {
  width: 100% !important;
}
.accounts-dropdown .dropdown-toggle[aria-expanded="true"],
.taxcode-dropdown .search-dropdown[aria-expanded="true"] {
  background: var(--white-fill)
    url("../../../../assets/images/arrow-drop-right-inverted.svg") no-repeat
    right 4px center !important;
  background-size: 20px 20px;
}
.accounts-dropdown .dropdown-menu {
  /* inset: initial !important; */
  min-width: 200px !important;
  /* transform: translate(0, 44px) !important; */
  padding: 0;
  right: -1px !important;
  /* border-radius: 12px; */
  border-radius: 4px;
  -webkitbox-shadow: 0 6px 12px 0 var(---black-shadow1);
  box-shadow: 0 6px 12px 0 var(---black-shadow1);
  border: solid 1px var(--warm-white);
  background-color: var(--white-fill);
  max-height: 198px;
  overflow-x: hidden;
  overflow-y: auto;
}
.accounts-dropdown
  .dropdown-menu:not(.accounts-dropdown.drop-up .dropdown-menu) {
  inset: initial !important;
  transform: translate3d(0px, 44px, 0px) !important;
}
.accounts-dropdown.drop-up .dropdown-menu {
  inset: auto auto 88px 0px !important;
}
.accounts-dropdown .dropdown-menu li {
  padding: 0;
  width: 100%;
}
.accounts-dropdown .dropdown-menu li:first-child,
.accounts-dropdown .dropdown-menu li:first-child a {
  /* border-radius: 12px 12px 0 0!important; */
  border-radius: 4px 4px 0 0 !important;
}
.accounts-dropdown .dropdown-menu li:last-child,
.accounts-dropdown .dropdown-menu li:last-child a {
  /* border-radius: 0 0 12px 12px!important; */
  border-radius: 0 0 4px 4px !important;
}
.accounts-dropdown .dropdown-menu a {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 12px 12px 10px 12px;
  background-color: var(--white-fill) !important;
  border-bottom: 1px solid var(--approx-whisper) !important;
}
.accounts-dropdown .dropdown-menu li:last-child a {
  border-bottom: 0 !important;
}
.accounts-dropdown .dropdown-menu a.active,
.accounts-dropdown .dropdown-menu a:hover {
  background-color: var(--whisper) !important;
}
.accounts-dropdown .dropdown-menu a span {
  display: block;
  width: 100%;
  padding: 2px 1px 2px 0;
}
.accounts-dropdown .dropdown-menu a .account {
  font-size: 14px;
  font-weight: 600;

  line-height: 1.71;
  letter-spacing: -0.11px;
  color: var(--charcoal-grey);
  text-transform: none;
}
.accounts-dropdown .dropdown-menu a .account-type {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: var(--battleship-grey);
  margin-top: 4px;
  white-space: normal;
}

.accounts-dropdown .dropdown-menu .no-result {
  cursor: default !important;
}
.accounts-dropdown .dropdown-menu span.no-result {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  text-align: center;
  cursor: text !important;
}
.accounts-dropdown .dropdown-item {
  text-align: start !important;
}
.accounts-dropdown .search-dropdown.discount-account-selector {
  width: 100% !important;
  border-radius: 0px 6px 6px 0px !important
}
.accounts-dropdown .search-dropdown.sales-purchase-default-account-select {
  width: 100% !important;
}
.accounts-dropdown .sales-default-account-select {
  width: 100% !important;
}
