/* .creditnote-list-wrapper #invoice_list_table.cd-note-table th,
.creditnote-list-wrapper #invoice_list_table.cd-note-table td {
  width: auto !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(1),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(1) {
  min-width: 179px !important;
  padding-right: 50px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(2),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(2) {
  min-width: 179px !important;
  padding-right: 24px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(3),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(3) {
  min-width: 220px !important;
  padding-right: 24px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(4),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(4) {
  min-width: 188px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(5),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(5) {
  min-width: 102px !important;
  padding-right: 24px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(6),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(6) {
  min-width: 113px !important;
  padding-right: 10px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(7),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(7) {
  min-width: 120px !important;
}
.creditnote-list-wrapper #invoice_list_table.cd-note-table th:nth-child(8),
.creditnote-list-wrapper #invoice_list_table.cd-note-table td:nth-child(8) {
  min-width: 44px !important;
} */
#custom_field_table{
  width: auto;
  -webkit-box-shadow: 0 8px 16px -2px var(---black-shadow3);
  box-shadow: 0 8px 16px -2px var(---black-shadow3);
  border-radius: 10px !important;
  border: solid 1px var(--warmWhite);
}
#cn-pr-list-table{
  width: 100% !important;
}
#cn-pr-list-table thead, th:first-child{
  border-top: none !important;
}
#cn-pr-list-table .itemdate{
  width: auto !important;
}
#dn-pm-list-table{
  width: 100% !important;
}
#dn-pm-list-table thead, th:first-child{
  border-top: none !important;
}
#dn-pm-list-table .itemdate{
  width: auto !important;
}
#invoice #cn-pr-list-table td{
  padding-top: 12px !important;
  vertical-align: middle;
}
#cn-pr-list-table .action span{
  top: 0;
}
#invoice #dn-pm-list-table td{
  padding-top: 12px !important;
  vertical-align: middle;
}
#dn-pm-list-table .action span{
  top: 0;
}
#invoice-table-wrapper table tbody tr.no-item td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}