.subscription-view a.dropdown-item {
  margin: 0px !important;
  height: auto !important;
}

.subscription-view .detail-top-action-menu>ul>li img {
  transform: translate(0px, 3px);
}

.subscription-view .address-wrapper .address-box-container .boxed-item h5 {
  position: relative;
  display: flex;
  left: -16px;
  align-items: center;
  border-radius: 0 4px 4px 0;
  background-color: var(--solitude);
  padding: 1px 14px 1px 16px;
}

.subscription-view .tab-content-wrapper .section-blocks .addressSection .address-wrapper .address-container {
  display: inline-block;
  position: relative;
  width: 100%;
  flex: 1;
}

.subscription-view .tab-content-wrapper .section-blocks .addressSection .address-wrapper .address-container .address-box-wrapper {
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  flex: 1;
}

@media (min-width: 620px) and (max-width: 1024px) {
  .subscription-view .addressSection {
    width: 80%;
  }

  .subscription-view .tab-content-wrapper .section-blocks .section-content-wrapper {
    flex-direction: row;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .billing-address-container:after {
    display: flex;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container {
    position: relative;
    margin-top: 0px;
  }

  .subscription-view .tab-content-wrapper .shipping-address-container .address-box-wrapper {
    margin: 0 0 26px 26px;
  }

  .subscription-view .tab-content-wrapper .address-wrapper .shipping-address-container h3 {
    padding-left: 30px;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container .add-new.addnew-address {
    left: 30px;
  }
}

@media (min-width: 1482px) {
  .subscription-view .addressSection {
    width: 40%;
  }
}

@media (min-width: 1358px) and (max-width: 1481px) {
  .subscription-view .addressSection {
    width: 45%;
  }
}

@media (min-width: 1245px) and (max-width: 1357px) {
  .subscription-view .addressSection {
    width: 50%;
  }
}

@media (min-width: 1110px) and (max-width: 1244px) {
  .subscription-view .addressSection {
    width: 60%;
  }
}

@media (min-width: 1024px) and (max-width: 1109px) {
  .subscription-view .addressSection {
    width: 65%;
  }
}

@media (min-width: 521px) and (max-width: 620px) {
  .subscription-view .addressSection {
    width: 100%;
  }
}

@media (max-width: 485px) {
  .subscription-view .tab-content-wrapper .section-blocks .section-content-wrapper {
    flex-direction: column;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .address-container {
    width: 100%;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .billing-address-container:after {
    display: none;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container {
    position: relative;
    margin-top: 20px;
  }

  .subscription-view .tab-content-wrapper .shipping-address-container .address-box-wrapper {
    margin: 0 26px 26px 0;
  }

  .subscription-view .tab-content-wrapper .address-wrapper .shipping-address-container h3 {
    padding-left: 0;
  }

  .subscription-view .tab-content-wrapper .section-blocks .address-wrapper .shipping-address-container .add-new.addnew-address {
    left: 0;
  }
}

.section-blocks-item-table {
  padding-bottom: 26px;
}

.subscription-view .plan-description {
  padding: 22px;
  border-bottom: 1px solid #e9e9e9;
}

.subscription-view .plan-description h6 {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: var(--charcoal-grey);
  margin-bottom: 2px;
}

.subscription-view .plan-description .plan-tag {
  letter-spacing: normal;
  color: var(--battleship-grey);
  font-size: 12.5px;
}

.subscription-view .card-body {
  margin-bottom: 38px;
}

.subscription-view .text-table-end {
  text-align: right;
}

.subscription-view .text-table-center {
  text-align: center;
}

.subscription-view .subscription-details-plan-addon-coupon-heading {
  font-size: 18px;
  font-weight: 700;
}

.subscription-unbilled-table thead,
.subscription-unbilled-table tr,
.subscription-unbilled-table th,
.subscription-unbilled-table td,
.subscription-unbilled-table tbody {
  border: none;
}

.subscription-unbilled-table .delete-img-icon {
  padding-bottom: 3px;
  margin-right: 2px;
}

.subscription-unbilled-table {
  background-color: #f5f5f5;
}

.subscription-unbilled-table .title-header {
  font-weight: 700;
  font-size: 18px;
}

.subscription-unbilled-table th {
  text-transform: none;
  font-size: 15px;
}

.subscription-unbilled-table .delete-btn {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 22px;
}

.coupon-description-container {
  display: flex;
  justify-content: space-between;
  min-width: 1054px;
}

.coupon-discount-amount-container {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
}

.subscription-view .left-align {
  text-align: left !important;
}

.subscription-pricing-form .item-cart {
  max-width: 100%;
}

.subscription-view #invoice .table-section tbody td {
  line-height: 1.5 !important;
}