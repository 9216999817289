.reportingtag-jv .table-section table.inv-preview-table,
#invoice .table-section table.bills-preview-table,
#invoice .table-section table.cnote-preview-table,
#invoice .table-section table.dnote-preview-table {
  width: 100%;
  min-width: 1054px;
  margin-bottom: 0;
}

.reportingtag-paper {
  z-index: 1200000000 !important;
}
.report-tags-table {
  min-width: auto;
}
.report-tags-table td span.account_name,
.report-tags-table td .select-reporting-tag-modal {
  display: inline-block;
}
.report-tags-table td span.account_name {
  max-width: 320px;
}
.report-tags-table td .select-reporting-tag-modal {
  margin-left: 12px;
}
.report-tags-table .select-reporting-tag-modal .open-reporting-tag-modal-btn {
  position: relative;
  top: -3px;
}
.report-tags-table tr th:nth-child(2),
.report-tags-table tr th:nth-child(3),
.report-tags-table tr td:nth-child(2),
.report-tags-table tr td:nth-child(3) {
  text-align: right;
}
.report-tags-table tr th .report-tags-table tr td {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.report-tags-table tr th:first-child .report-tags-table tr td:first-child {
  padding-left: 24px !important;
}
.report-tags-table tr th:last-child .report-tags-table tr td:last-child {
  padding-right: 24px !important;
}
.reporting-tag-footer-btn-grp {
  position: fixed;
  bottom: 0;
  background: #ffffff;
  padding: 24px 0px;
}
.journal-list-table h2 {
  font-size: 18px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.23px;
  color: var(--charcoal-grey);
  clear: both;
  margin-bottom: 10px;
  max-width: 500px;
  float: left;
}
.journal-list-table-mapping-wrapper {
  margin-top: 30px;
}
.journal-list-table .table-wrap {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.journal-list-table .table-section {
  margin-bottom: 30px;
}

.journal-list-table table {
  min-width: 700px !important;
}
