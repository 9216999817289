.maintenance-page-wrapper {
    width: 100%;
    min-height: 100vh;    
    text-align: center;
    padding: 24px 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    text-align: center;
}
.maintenance-page-wrapper .logo-link {
    width: auto;
    height: 70px;
    padding: 0;
    margin: 0 0 62px 0;
}
.maintenance-content-wrapper {
    max-width: 700px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    box-shadow: 0 12px 30px 0 var(--black-shadow2);
    border: solid 1px var(--warm-white);
    background-color: var(--white-fill);    
}
.maintenance-content-wrapper .maintenance-header {
    width: 100%;
    padding: 64px 48px 0 48px;
}
.maintenance-content-wrapper .maintenance-header  img {

}
.maintenance-content-wrapper .maintenance-header h1 {   
    font-size: 24px;
    font-weight: bold;  
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--charcoal-grey);
    margin: 67px auto 48px auto;
}
.maintenance-content-wrapper .maintenance-message {
    padding: 0 48px 64px 48px;
}
.maintenance-content-wrapper .maintenance-message p {
    font-size: 16px;
    font-weight: normal;    
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--rich-bloack);
    margin-bottom: 25px;
}

.logo {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.message-footer {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    border-top: solid 1px var(--warm-white);
    padding: 27px 48px;
    }
.message-footer .signature-wrapper {
    text-align: left;
    max-width: calc(100% - 130px);
}

.message-footer .signature-wrapper .signature-label {
    display: block;
    flex-grow: 0;    
    font-size: 14px;
    font-weight: 600;    
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: var(--battleship-grey);
    clear: both;
}
.message-footer .signature-wrapper p {
    flex-grow: 0;  
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: var(--rich-bloack);
    margin: 0;
    clear: both;
    text-align: left;
}
.logout-btn {
    width: auto;
    min-width: 110px;
    height: auto;
    font-size: 15px;
    font-weight: bold;  
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--white-fill);
    padding: 12px 32px;
}
.logout-btn:hover {
    color: var(--white-fill);
    background-color: #d52525 !important;
}