.item-details-wrapper label {
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 2 !important;
  letter-spacing: normal !important;
  color: var(--battleship-grey) !important;
  text-transform: uppercase;
  margin-bottom: 8px !important;
}

.item-details-wrapper .items-entry-wrapper .mb-8 {
  margin-bottom: 8px !important;
}

.item-details-wrapper .w-380 {
  width: 36.225%;
  min-width: 380px;
  flex: 0 0 36.225%;
}

.item-details-wrapper .w-310 {
  width: 29.4397%;
  min-width: 310px;
  flex: 0 0 29.4397%;
}

.item-details-wrapper .width-100 {
  width: 9.5329%;
  min-width: 100px;
  flex: 0 0 9.5329%;
  max-width: none !important;
}

.item-details-wrapper .w-auto {
  width: auto;
}
.item-details-wrapper  .w-200 {
    width: 19.06578%;
    min-width: 200px;
    max-width: none !important;
    flex: 0 0 19.06578%;
}
.item-details-wrapper  .w-140 {
  width: 12.8226%;
  min-width: 140px;
}
.item-details-wrapper  .delete-container-btn {
  background: transparent;
  border: 0;
}

.item-details-wrapper .delete-line-item {
  top: 16px;
  right: 15px;
}

@media (max-width: 1024px) {
  .tax-amt-col .label-wrapper label { 
    padding-right: 5px;
  }
}