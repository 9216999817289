.recurring-invoice-view a.dropdown-item {
  margin: 0px !important;
  height: auto !important;
}

.recurring-invoice-view .detail-top-action-menu > ul > li img {
  transform: translate(0px, 3px);
}

.recurring-invoice-view .address-wrapper .address-box-container .boxed-item h5 {
  position: relative;
  display: flex;
  left: -16px;
  align-items: center;
  border-radius: 0 4px 4px 0;
  background-color: var(--solitude);
  padding: 1px 14px 1px 16px;
}

.recurring-invoice-view .tab-content-wrapper
  .section-blocks
  .addressSection
  .address-wrapper
  .address-container {
  display: inline-block;
  position: relative;
  width: 100%;
  flex: 1;
}
.recurring-invoice-view .tab-content-wrapper
  .section-blocks
  .addressSection
  .address-wrapper
  .address-container
  .address-box-wrapper {
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  flex: 1;
}

@media (min-width: 620px) and (max-width: 1024px) {
  .recurring-invoice-view .addressSection {
    width: 80%;
  }
  .recurring-invoice-view .tab-content-wrapper .section-blocks .section-content-wrapper {
    flex-direction: row;
  }

  .recurring-invoice-view .tab-content-wrapper
    .section-blocks
    .address-wrapper
    .billing-address-container:after {
    display: flex;
  }
  .recurring-invoice-view .tab-content-wrapper
    .section-blocks
    .address-wrapper
    .shipping-address-container {
    position: relative;
    margin-top: 0px;
  }
  .recurring-invoice-view .tab-content-wrapper .shipping-address-container .address-box-wrapper {
    margin: 0 0 26px 26px;
  }
  .recurring-invoice-view .tab-content-wrapper .address-wrapper .shipping-address-container h3 {
    padding-left: 30px;
  }
  .recurring-invoice-view .tab-content-wrapper
    .section-blocks
    .address-wrapper
    .shipping-address-container
    .add-new.addnew-address {
    left: 30px;
  }
}

@media (min-width: 1482px) {
  .recurring-invoice-view .addressSection {
    width: 40%;
  }
}

@media (min-width: 1358px) and (max-width: 1481px) {
  .recurring-invoice-view .addressSection {
    width: 45%;
  }
}

@media (min-width: 1245px) and (max-width: 1357px) {
  .recurring-invoice-view .addressSection {
    width: 50%;
  }
}

@media (min-width: 1110px) and (max-width: 1244px) {
  .recurring-invoice-view .addressSection {
    width: 60%;
  }
}

@media (min-width: 1024px) and (max-width: 1109px) {
  .recurring-invoice-view .addressSection {
    width: 65%;
  }
}

@media (min-width: 521px) and (max-width: 620px) {
  .recurring-invoice-view .addressSection {
    width: 100%;
  }
}

@media (max-width: 485px) {
  .recurring-invoice-view .tab-content-wrapper .section-blocks .section-content-wrapper {
    flex-direction: column;
  }
  .recurring-invoice-view .tab-content-wrapper .section-blocks .address-wrapper .address-container {
    width: 100%;
  }
  .recurring-invoice-view .tab-content-wrapper
    .section-blocks
    .address-wrapper
    .billing-address-container:after {
    display: none;
  }
  .recurring-invoice-view .tab-content-wrapper
    .section-blocks
    .address-wrapper
    .shipping-address-container {
    position: relative;
    margin-top: 20px;
  }
  .recurring-invoice-view .tab-content-wrapper .shipping-address-container .address-box-wrapper {
    margin: 0 26px 26px 0;
  }
  .recurring-invoice-view .tab-content-wrapper .address-wrapper .shipping-address-container h3 {
    padding-left: 0;
  }
  .recurring-invoice-view .tab-content-wrapper
    .section-blocks
    .address-wrapper
    .shipping-address-container
    .add-new.addnew-address {
    left: 0;
  }
}

.section-blocks-item-table {
  padding-bottom: 26px;
}

.ri-view-container .pagination-wrapper {
  margin-top: 35px;
}

.ri-item-view-table thead th:last-child,
.ri-item-view-table tbody td:last-child {
  float: right;
}