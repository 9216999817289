.tb-report-section .report-table-accordion .item-row .item-col.debit-col,
.tb-report-section .report-table-accordion .item-row .item-col.credit-col {
    width: auto;
    min-width: 180px;
    padding-right: 20px !important;
}
.tb-report-section .report-table-accordion .table-footer .summary-row .item-col {
    padding: 20px 0 !important;
}
.tb-report-section .report-table-accordion .table-footer .summary-row .item-col,
.tb-report-section .report-table-accordion .table-footer .summary-row .item-col span {
    
    font-size: 18px;    
    
    
    line-height: normal;
}
.tb-report-section .report-table-accordion .table-footer .summary-row .item-col.total-label span {
    font-weight: bold;
    color: var(--battleship-grey);
    letter-spacing: -0.23px;
    padding-right: 80px !important;
}
.tb-report-section .report-table-accordion .table-footer .item-col.debit-col,
.tb-report-section .report-table-accordion .table-footer .item-col.debit-col span {
    text-align: left !important;
}
.tb-report-section .report-table-accordion .table-footer .item-col.debit-col,
.tb-report-section .report-table-accordion .table-footer .item-col.credit-col,
.tb-report-section .report-table-accordion .table-footer .item-col.debit-col span,
.tb-report-section .report-table-accordion .table-footer .item-col.credit-col span {    
    font-weight: 900;    
    letter-spacing: normal;
    text-align: right;
    color: var(--charcoal-grey);
}
.tb-report-section .report-table-accordion .table-footer .item-col.debit-col,
.tb-report-section .report-table-accordion .table-footer .item-col.credit-col {
    padding-right: 20px !important;
}
.tb-report-section .report-table-accordion .table-collapsible-item.bottom-border {
    border-bottom: 0;
}
/* .tb-report-section .report-table-accordion .table-collapsible-item.bottom-border:last-child {
    border-bottom: 0 !important;
} */
.tb-report-section .report-table-accordion .item-row,
.tb-report-section .report-table-accordion .item-row .item-col {
    height: 36px !important;
    line-height: 36px !important;
}

.tb-report-section .report-table-accordion .summary-footer .summary-row {
    height: 62px !important;
    line-height: 62px !important;
    background-color: var(--white-fill);
}
.tb-report-section .report-table-accordion .collapsible-btn {
    margin-top: -1px!important;
}

.tb-report-section .report-table-accordion .item-row .item-col.debit-col,
.tb-report-section .report-table-accordion .item-row .item-col.credit-col,
.tb-report-section .report-table-accordion .item-row .item-col.debit-col span,
.tb-report-section .report-table-accordion .item-row .item-col.credit-col span {
    text-align: right !important;
}
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .summary-row .item-col.total-label span {
    padding-right: 0 !important;
    font-size: 14px;
    font-weight: 500;
    color: var(--charcoal-grey);
    line-height: 0.71;
    letter-spacing: normal;
    text-align: right;
}

.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.debit-col,
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.credit-col,
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.debit-col span,
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.credit-col span {    
    font-size: 14px;
    font-weight: 500;
    color: var(--charcoal-grey);
    line-height: 0.71;
    letter-spacing: normal;
    text-align: right;
    color: var(--charcoal-grey);
}
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .summary-row .item-col {
    padding: 0 !important;    
}
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .summary-row .item-col,
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .summary-row .item-col span {
    display: block;
    line-height: 42px !important;
}

.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.total-label,
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.debit-col,
.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-col.credit-col,
.tb-report-section .report-table-accordion .table-footer.summary-footer .item-col.debit-col,
.tb-report-section .report-table-accordion .table-footer.summary-footer .item-col.credit-col {
    padding-right: 20px !important;
}

.tb-report-section .report-table-accordion .table-footer.account-summary-footer .item-row.text-bold * {
    font-weight: 900 !important;
}

.category-amount-text-bold {
    font-weight: 900 !important;
}