.reusable-form-input-field label {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.18px;
  color: var(--charcoal-grey);
  float: left;
  margin-bottom: 12px !important;
}

.reusable-form-input-field input[type="text"],
.reusable-form-input-field input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  padding: 11px 12px;
  border-radius: 6px;
  border: solid 1px var(--new-whisper);
  background-color: var(--white-fill);
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
/* .reusable-form-input-field  input::-webkit-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-field  input::-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-field  input:-ms-input-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-field  input:-moz-placeholder {
    color: rgba(var(--charcoal-grey-rgb), 0.55);
}
.reusable-form-input-field  input:focus-visible {
    outline: 0;
} */
.reusable-form-input-field input:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.form-input-icon-wrapper.with-side-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-input-field-icon {
  margin-left: 10px;
}
.reusable-form-input-field{
display: flex;
flex-direction: column;
}
