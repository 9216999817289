#sidebar {
    width: 95px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;    
    margin: 75px 0 0 0;
    -webkit-box-shadow: 0 2px 1px 0 var(--black-shadow0);
    box-shadow: 0 2px 1px 0 var(--black-shadow0);
    background-color: var(--new-approx-alice-blue-2);
    transition: 0.5s;
    padding-top: 32px;
}
#sidebar:before {
    content: '';
    width: 100%;
    height: 33px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--new-approx-alice-blue-2);
    z-index: 1;
}
#sidebar.expanded {
    /* width: 226px; */
    width: 236px;
}

#sidebar .nav-item * {
    float: left;
    text-align: initial;
}
#sidebar .submenu {
    list-style: none;
    border-radius: 0;    
    width: calc(100% + 12px);
    position: relative;
    padding-bottom: 7px;
    padding-left: 70px;
    padding-right: 10px;
    list-style: none;
    border-radius: 0;
    top: 12px;
    left: 0;    
}
#sidebar .submenu li {
    width: 100%;
    padding: 0;
    clear: both;
}
#sidebar .nav-item .submenu li a {
    font-size: 15px;   
    line-height: normal;
    letter-spacing: -0.19px;
    color: var(--charcoal-grey);
}
.menu-list {
    width: 100%;
    float: left;
    margin-top: 0;
    padding: 0px;
}
.sidebar-custom {
    width: 96px;
    max-width: 96px;
    height: auto;
    min-height: calc(100vh - 75px) !important;  
    z-index: 1314;
    transition: 0.5s;
}
.sidebar-custom.expanded {
    /* width: 226px;
    max-width: 226px; */
    width: 236px;
    max-width: 236px;
}


#sidebar .submenu li a {
    display: block;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
    padding: 12px 0;
}
#sidebar .submenu li a.current,
#sidebar .submenu li a:hover,
#sidebar .submenu li a:focus {
    color: var(--cerulean-blue);
}
#sidebar .submenu li a:focus {
    outline: 0 !important;
}
.menu-list, 
.menu-wrapper {
    float: left;
    margin-top: 0;
    padding: 0;
    width: 100%;
}
.menu-wrapper { 
    height: calc(100vh - 231px);
    max-height: calc(100vh - 231px);
    overflow: hidden;
    overflow-y: auto;
}

#sidebar .nav-item:hover a.menu-item,
#sidebar .nav-item.selected .nav-link.menu-item {
    background-color: var(--solitude);
}

/* #sidebar .nav-item.selected .menu-item {
background: none;
} */

#sidebar .nav-item:hover a span,
#sidebar .nav-item a:focus span {
    color: var(--cerulean-blue);
}
#sidebar .nav-item a.menu-item {
    color: var(--charcoal-grey);
}
#sidebar .nav-item a.menu-item {
    display: inline-block;
    transition: 0.2s;
    width: 100%;
    position: relative;
    padding: 12px 13px 12px 30px;
    color: var(--charcoal-grey);
    font-size: 15px;
    font-weight: bold;
}
#sidebar .nav-item a.menu-item:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 24px;
    left: 0;
    top: 11px;
    background-color: transparent;
}
#sidebar .nav-item.selected a.menu-item:before {
    background-color: var(--cerulean-blue);
}
#sidebar .nav-item {
    padding: 0;
    margin-bottom: 8px;
}
#sidebar .nav-item:last-child {
    margin-bottom: 0;
}
#sidebar .nav-item img {
        
}
#sidebar .nav-item svg {
        width: 24px;
        height: 24px;
        margin: 0 16px 0 0;
}

#sidebar .nav-item i {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 16px 0 0;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
}
#sidebar .nav-item a.menu-item .menu-text {
    margin-top: 7px;
}

#sidebar .nav-item.selected .menu-text {
    color: var(--cerulean-blue);
}

#sidebar .nav-item:hover svg path:last-child,
#sidebar .nav-item.selected svg path:last-child {
    fill: var(--cerulean-blue);
}
#sidebar .nav-item a.menu-item span {
  font-size: 15px;
  font-weight: bold;
  line-height: 0.67;
  letter-spacing: normal;
}


/* #sidebar .nav-item .select-bar {
width: 5px;
height: 24px;
float: left;
margin-right: 25px;
} */

/* #sidebar .nav-item.selected .select-bar {
background-color: var(--cerulean-blue);
} */

.sidebar-search span {
    left: 28px;
    top: 10px;
    margin-right: 12px;
}
.sidebar-search span img {
    float: left;
}
.sidebar-search {
    position: relative;
    padding: 0 14px;
    clear: both;
}
.sidebar-search input {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 10px 10px 10px 44px;
    font-size: 15px;
    color: var(--slate);
    border-radius: 8px;
    -webkit-box-shadow: 0 3px 6px 0 var(--black-shadow2);
    box-shadow: 0 3px 6px 0 var(--black-shadow2);
    background-color: var(--white-fill);
}
.pinned-wrapper {
    width: 100%;
    padding: 24px 14px 12px 14px;
    float: left;
}
.nav.pinned {
    width: 100%;
    max-width: 198px;
    min-width: 40px;
    height: 48px;
    margin: 0;
    padding: 12px 16px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
}

.nav.pinned.selected,
.nav.pinned:hover {
    background-color: var(--lavender);
}

.nav.pinned .menu-text {
    font-size: 15px;
    font-weight: bold;
    line-height: 0.8;
    letter-spacing: -0.19px;
    color: var(--charcoal-grey);
    padding-top: 7px;
}
.nav.pinned.selected .menu-text,
.nav.pinned:hover .menu-text {
    color: var(--cerulean-blue);
}

.nav.pinned img {
    margin: 0 16px 0 0;
}

.nav.pinned svg {
    width: 24px;
    height: 24px;
    margin: 0 16px 0 0;
}
.nav.pinned:hover svg path:last-child,
.nav.pinned.selected svg path:last-child {
    fill: var(--cerulean-blue);
}

.sidebar-togglebtn {
    width: 30px;
    height: 30px;
    padding: 5px;
    -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow8);
    box-shadow: 0 6px 12px 0 var(--black-shadow8);
    border: solid 1px var(--new-white-smoke);
    border-radius: 50%;
    background-color: var(--white-fill);
    /* right: -15px;
    top: -15px; */
    z-index: 1315;
    outline: 0;
    top: 62px;
    left: 77px;
    transition: 0.5s;
}
.sidebar-togglebtn.expanded {    
    /* left: 210px; */
    left: 220px;
}
.sidebar-togglebtn img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    border: 0;
    outline: 0;
    box-shadow: none;
    float: left;
}
.sidebar-togglebtn img {
    transform: rotate(180deg);
}
.sidebar.expanded .sidebar-togglebtn img,
.sidebar-togglebtn.expanded img {
    transform: rotate(0deg);
}
.sidebar-header {
    width: 100%;
    padding-left: 16px;
    margin-bottom: 20px;
}
.sidebar-header h2 {
    font-size: 24px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: var(--charcoal-grey);
}
#sidebar .submenu li a:focus,
#sidebar .nav-item a.menu-item:focus,
#sidebar .nav-item a:focus {
  outline: 0 !important;
}
.settings-sidebar .sidebar-search {
    margin-bottom: 20px;
}
.settings-sidebar #sidebar .nav-item a.menu-item {
    padding: 12px 13px 13px 16px;
}
.settings-sidebar #sidebar .nav-item a.menu-item span {
    line-height: 1;
}
.settings-sidebar #sidebar .nav-item a.menu-item .menu-text {
    margin-top: 0;
}
.settings-sidebar #sidebar .nav-item a.menu-item:before {
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 2px;
}
.settings-sidebar #sidebar .nav-item {
    margin-bottom: 7px;
}
.settings-sidebar #sidebar .nav-item:last-child {
    margin-bottom: 0;
}

.settings-sidebar #sidebar .submenu {
    width: 100%;    
    padding-left: 26px;
}
.settings-sidebar #sidebar .submenu .submenu {
    padding-left: 16px;
    padding-right: 0;
}
@media (max-width: 767px) {
    .sidebar-custom {
        position: fixed;        
        top: -200vh;
        left: 0;
        width: 100% !important;
        max-width: none !important;
        z-index: 1309;
        margin-top: 0;
        padding: 0;
        /* height: 100vh !important;
        min-height: auto !important; */
        transition: top 0.5s ease-in-out;
    }
    .sidebar-custom.expanded,
    #sidebar,
    #sidebar.expanded {
        width: 100% !important;
        max-width: none !important;
    }
    #sidebar {
        position: static;
        margin: 0!important;
        float: left;
    }
    #sidebar:before {
        display: none;
    }
    .sidebar-custom.show {
        top: 75px;
    }
    .settings-sidebar .back-button{
        display: none;
    }
}