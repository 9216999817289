.table-header,
.table-body,
.grid-table {
  width: 100%;
}
.float-left {
  float: left;
}
.grid-table {
  display: table;
  border-radius: 12px;
  -webkit-box-shadow: 0 6px 12px 0 var(--black-shadow1);
  box-shadow: 0 6px 12px 0 var(--black-shadow1);
  border: solid 1px var(--warm-white);
}
.item-row {
  display: flex;
  width: 100%;
}
.table-body .item-row {
  height: 36px;
}
.table-body .item-row:hover {
  background-color: var(--alice-blue);
}
.item-col,
.item-col.no-item span {
  font-size: 14px;
  font-weight: normal;
  line-height: 0.71;
  letter-spacing: normal;
  color: var(--charcoal-grey);
}
.table-body .account-col {
  width: calc(49% - 57px);
}
.auto-flex .item-col {
  flex: auto;
}
.table-header .item-col {
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: normal;
  color: var(--battleship-grey);
  text-transform: uppercase;
}
.item-col > span:not(.cn-form .item-col > span) {
  width: 100%;
  float: left;
  padding: 12px 0;
  white-space: nowrap;
}
.item-col > .coa_tree_description {
  padding: 10px 0;
}
.item-col > .coa_tree_description > span {
  display: block;
  width: 100%;
  padding: 2px 0;
}
.table-header .item-col:first-child {
  padding-left: 24px;
}
.account-col {
  width: calc(49% - 57px);
  flex: 1 1 auto;
}
.desc-col {
  width: 240px;
  flex: 1 1 auto;
}
.bal-col {
  width: 21%;
  flex: 1 1 auto;
}
.act-col {
  width: 106px;
  min-width: 106px;
  max-width: 106px;
  padding-left: 62px;
  padding-right: 24px;
  flex: 1 1 auto;
}
.act-col .action {
  padding: 7px 0;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.table-collapsible-item .btn-container {
  padding: 10px 10px;
  display: inline-block;
  border-left: 1px solid var(--approx-whisper);
  width: 38px;
  height: 100%;
}
.collapsible-btn {
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  box-shadow: 0;
  background: url("../../../assets/images/bold-arrow-down.svg") no-repeat center;
  background-size: 16px 16px;
  float: left;
  padding: 0;
}
.collapsible-btn.active {
  transform: rotate(-180deg);
}
.item-label {
  display: inline-block;
  width: calc(100% - 38px);
  margin-right: -4px;
  padding: 12px 14px 8px 10px;
  margin-left: 0;
}
.btn-container + .item-label {
  border-left: 1px solid var(--approx-whisper);
}
.plain-line-column {
  border-left: 1px solid var(--approx-whisper);
}
.account-col > div:first-child {
  border-left: 0;
}
.table-collapsible-content {
  width: 100%;
  transition: all 4s ease;
}
.table-collapsible-content:not(.show) {
  display: none;
}
.level-1 .item-label {
  width: calc(100% - 38px);
}
.level-2 .item-label {
  width: calc(100% - (38px * 2));
}
.level-3 .item-label {
  width: calc(100% - (38px * 3));
}
.level-4 .item-label {
  width: calc(100% - (38px * 4));
}
.level-5 .item-label {
  width: calc(100% - (38px * 5));
}
.level-6 .item-label {
  width: calc(100% - (38px * 6));
}
.level-1.no-child .item-label {
  width: 100%;
}
.left-float {
  float: left;
}
.right-float {
  float: right;
}
.parent-row.expanded .item-col:first-child .item-label,
.parent-row.expanded .item-col:first-child .item-label .acc-name {
  font-weight: bold;
}
.parent-row .item-col:first-child .item-label .acc-name img {
  margin-top: -2px;
  margin-right: 2px;
}
.horizontal-border {
  border-top: 1px solid var(--approx-whisper);
}
.left-border {
  border-left: 1px solid var(--approx-whisper);
}
.bottom-border {
  border-bottom: 1px solid var(--approx-whisper);
}
.plain-line-column {
  width: 38px;
  height: 36px;
  display: block;
}
.full-height {
  height: 100%;
}
.table-body .no-item .account-col,
.no-item .item-label {
  width: 100%;
}
.table-body .item-row:hover .bottom-border {
  border-bottom: 0;
}
.grid-table .table-header .item-col[data-orderable="true"] {
  cursor: pointer;
}
.grid-table .table-header .item-col[data-orderable="true"] span {
  width: auto !important;
  position: relative;
  padding-right: 20px;
}
.grid-table .table-header .item-col[data-orderable="true"] span:before {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: left top;
  bottom: 16px;
  right: 8px;
}
.grid-table .table-header .item-col[data-orderable="true"] span:after {
  position: absolute;
  content: "";
  width: 8px;
  height: 16px;
  background: url("../../../assets/images/sorting-icon.png") no-repeat;
  background-position: right top;
  right: 0;
  bottom: 16px;
}
.grid-table .table-header .item-col.ascending span:before {
  opacity: 1;
}
.grid-table .table-header .item-col.ascending span:after {
  opacity: 0.5;
}
.grid-table .table-header .item-col.descending span:before {
  opacity: 0.5;
}
.grid-table .table-header .item-col.descending span:after {
  opacity: 1;
}
#addAccountModal
  .tree-view-container
  .MuiTreeView-root
  > li
  .MuiTreeItem-content
  > div
  > .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
  position: relative;
  top: -2px;
  margin-right: 6px;
}
