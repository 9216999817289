* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.no-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: 0;
  background: transparent;
}

.nav-search {
  margin: 1px 16px 0.2px 0px;
}

.content-body {
  margin-top: 75px;
}

.main {
  overflow: hidden;
  padding-left: 25px;
  padding-right: 25px;
  /* width: calc(100% - 226px); */
}
.main > .main-card {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  border: 0;
  margin: 35px auto 40px auto;
}
.main-card .card-body-wrapper {
  width: calc(100% - 10px);
  margin: 0 auto;
  border: 0;
  flex-direction: column;
}
.modal {
  z-index: 1325 !important;
}
.modal-backdrop {
  z-index: 1320 !important;
}

.overflowX-auto {
  overflow-x: auto;
}
.overflowX-hidden {
  overflow-x: hidden;
}
.card .card-body {
  height: auto;
  flex: 0 0 auto;
}
.card .card-body.no-list-item-body + .card-footer,
.card .card-body.no-list-item-body .card-footer {
  display: none;
}
#react-select-2-placeholder {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}

.custom-select.error div[class*="-control"] {
  border-radius: 6px !important;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
div[class*="-group"].error {
  border-radius: 6px !important;
  -webkit-box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  box-shadow: 0 2px 6px 0 var(--black-shadow1) !important;
  border: solid 1px var(--red) !important;
  background-color: var(--rose-white) !important;
}
div[class*="-group"].error input[type="text"] {
  background-color: var(--rose-white) !important;
}

/* .settings-sidebar .sidebar-header {
  padding-left: 16px;
} */

.fixed-table {
  table-layout: fixed;
}
*:focus-visible,
.logout-btn:focus-visible {
  outline: 0 !important;
}
.back-button {
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  box-shadow: none;
  width: 24px;
  height: auto;
  background: transparent;
  padding: 3px 0;
  margin-right: 12px;
}
.back-button img,
.back-button svg {
  width: 100%;
  height: 100%;
  float: left;
}
.back-button img {
  height: auto;
}
input::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  text-transform: none;
}
input::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  text-transform: none;
}
input:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  text-transform: none;
}
input:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
  text-transform: none;
}
textarea {
  resize: none;
}
textarea.error {
  border: solid 1px var(--red);
  background-color: var(--rose-white);
}
textarea::-webkit-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
textarea::-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
textarea:-ms-input-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}
textarea:-moz-placeholder {
  color: rgba(var(--charcoal-grey-rgb), 0.55);
}

.custom-select div[class$="-indicatorSeparator"],
.custom-select span[class$="-indicatorSeparator"] {
  display: none !important;
}

/* *:focus {
  outline: 1px solid var(--cerulean-blue) !important;
} */

.main input:focus,
.main textarea:focus,
.react-multi-email.focused,
.custom-select .css-1pahdxg-control,
.bootstrap-select button.dropdown-toggle:focus,
.search-dropdown.dropdown-toggle:focus,
.sidebar-search input:focus,
/* #upload_section .dropzone:focus, */
.item-grid-account .taxcode-dropdown .search-dropdown:focus,
.multiSelectContainer .searchWrapper.focused,
.add-module-item-form .multiSelectContainer .searchWrapper.focused,
.daterange-wrapper:focus #reportrange,
.daterange-wrapper #reportrange:focus,
.org-selection:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}

/* .add-new:focus,
.edit-tags-form-wrap button:focus {
  color: var(--booger);
} */
ul.dropdown-menu li a:focus {
  outline-offset: -2px;
}
.react-multi-email input:focus,
.custom-select .css-1pahdxg-control input:focus {
  border: 0 !important;
}
a[role="button"]:focus {
  outline: 0 !important;
}

.accounts-dropdown .dropdown-item:active,
.accounts-dropdown .dropdown-item:active span,
.accounts-dropdown .dropdown-item.active,
.accounts-dropdown .dropdown-item.active span,
.accounts-dropdown .dropdown-item:focus,
.accounts-dropdown .dropdown-item:focus span {
  background-color: var(--whisper) !important;
}

.item-grid-account .discount-selector .accounts-dropdown input:focus,
.item-col .tax-selector .tax-select div.css-1pahdxg-control {
  border-left: 1px solid var(--cerulean-blue) !important;
}

/* .nav-oval-Copy:focus, */
/* .top-nav .nav-add-button:focus, */
/* .top-nav .organization-dropdwon button:focus, */
/* .profile-button#navbarDropdown:focus, */
/* #sidebarTogglebutton:focus, */
.form-button-wrapper button:focus,
.form-button-wrapper .cancel-button:focus,
.template-button-wrap button:focus,
.nav-pills li .green-button:focus,
.invite-btn:focus,
.cancel-btn:focus,
.green-button:focus,
.green-button[role="button"]:focus,
.btn-cancel:focus,
.btn-ok:focus,
.preview-top-butons button:focus,
.modal-footer button:focus,
.add-tax-btn-green .dropdown-toggle:focus,
.logout-btn:focus {
  box-shadow: none;
  outline: 2px solid var(--booger) !important;
  outline-offset: 3px;
}

.modal-header.close-button-container .close-btn:focus svg path:last-child,
.close-btn:focus svg path:last-child {
  fill: var(--red) !important;
}
.more-btn:focus svg path:last-child {
  fill: #50565b !important;
}
.more-btn + .dropdown-menu .disabled-menu-item .dropdown-item {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
.more-btn + .dropdown-menu .dropdown-item:focus,
.more-btn + .dropdown-menu .dropdown-item:focus span,
.more-btn + .dropdown-menu .dropdown-item:hover span {
  color: var(--cerulean-blue) !important;
}
.more-btn + .dropdown-menu .disabled-menu-item .dropdown-item:focus,
.more-btn + .dropdown-menu .disabled-menu-item .dropdown-item:focus span,
.more-btn + .dropdown-menu .disabled-menu-item .dropdown-item:hover span {
  color: var(--charcoal-grey) !important;
}

.multiSelectContainer input:focus {
  outline: 0 !important;
  border: 0 !important;
}
a.removeItem:focus,
a.delete-btn:focus,
a.upload-link:focus,
.edit-exchange-rate:focus {
  outline: 0 !important;
}
.removeItem:focus svg path:last-child,
.delete-btn:focus svg path:last-child,
.delete-icon:focus svg path:last-child {
  fill-opacity: 0.8 !important;
  fill: var(--red) !important;
}
a.manage-tags-link:focus,
a.add-customer-link:focus,
a.add-tags-button:focus,
a.upload-link:focus,
.add-new:focus,
.nav-pills li button:focus,
.edit-exchange-rate:focus,
.back-button:focus,
.add-more a:focus,
.add-link-option:focus span,
.more-link:focus,
.actions-wrap a:focus,
.report-body-links a:focus,
.img-upload-wrapper .preview-block a:focus,
.edit-address-link:focus {
  outline: 0 !important;
  opacity: 0.8 !important;
}
a.manage-tags-link:focus:before,
a.add-customer-link:focus:before,
a.add-tags-button:focus:before {
  outline: 0 !important;
  opacity: 0.8 !important;
}
.discount-type:focus,
.tree-view:focus {
  -webkit-box-shadow: 0px 0px 0.15rem 0px var(--cerulean-blue-shadow4);
  -moz-box-shadow: 0px 0px 0.15rem 0px var(--cerulean-blue-shadow4);
  box-shadow: 0px 0px 0.15rem 0px var(--cerulean-blue-shadow4);
}
.collapsible-btn:focus {
  opacity: 0.8 !important;
}

.multiSelectContainer li.option.highlightOption {
  background: var(--whisper) !important;
  color: var(--charcoal-grey);
  cursor: pointer;
}
.tab-link.top-action-lists .card-link:focus {
  opacity: 0.8 !important;
}

.disableActions {
  pointer-events: none !important;
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}
.custom-select {
  width: 100%;
  clear: both;
}
img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  image-rendering: -webkit-optimize-contrast;
}
.form-check-input {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
  border-color: var(--slate) !important;
}
.form-check-input[type="checkbox"] {
  border-radius: 2px !important;
}
input[type="checkbox"]:checked {
  background-color: var(--cerulean-blue) !important;
  background-image: url("../../assets/images/checkbox-checked.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 6px;
}
.form-check-input[type="checkbox"]:focus {
  border: 1px solid var(--cerulean-blue) !important;
  box-shadow: none !important;
  outline: 0 !important;
}
.form-outline {
  width: 100%;
  clear: both;
  float: left;
}
.modal-header .close-btn:hover svg path:last-child,
.modal-header .close-btn:focus svg path:last-child {
  fill: var(--red) !important;
}
.sys-transact-num {
  display: block;
  margin-left: auto;
  font-size: 14px;
  font-weight: normal;
  line-height: 30px;
  letter-spacing: normal;
  color: var(--battleship-grey);
  float: right;
}
.sys-transact-num .transact-num {
  font-weight: 600;
  color: var(--charcoal-grey);
}
.pbottom-14 {
  padding-bottom: 14px !important;
}
.pbottom-23 {
  padding-bottom: 23px !important;
}
.mtop-7 {
  margin-top: 7px !important;
}

.action-buttons {
  text-align: right;
  padding: 5px 0;
}
.action-buttons button {
  margin-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  box-shadow: none;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}
.action-buttons .blue-edit-btn {
  width: 100%;
  margin-left: 5px !important;
}
.action-buttons button[type="submit"] {
  padding-right: 5px !important;
}
.action-buttons button[type="reset"] {
  padding-left: 5px !important;
}
.action-buttons .edit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 0 0 22px;
  background: url("../../assets/images/edit-icon-blue.svg") transparent
    no-repeat left center;
  background-size: 16px 16px;
  border: 0;

  font-size: 14px;
  font-weight: normal;

  line-height: normal;
  letter-spacing: normal;
  color: var(--cerulean-blue);
}
.action-buttons .save,
.action-buttons .cancel,
.add-tag-form-wrap .save,
.add-tag-form-wrap .cancel,
.cancel-add,
.save-add {
  width: 20px;
  height: 20px;
  position: relative;
  object-fit: contain;
  background-size: 20px 20px;
  font-size: 0;
  color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  top: -5px;
}
.action-buttons .save,
.add-tag-form-wrap .save,
.save-add {
  background-image: url("../../assets/images/save-icon.svg");
  margin-right: 8px;
}
.action-buttons .cancel,
.add-tag-form-wrap .cancel,
.cancel-add {
  background-image: url("../../assets/images/cancel-icon.svg");
}
.left-align {
  text-align: left;
}
.right-align {
  text-align: right;
}
.MuiModal-root {
  z-index: 1321 !important;
}
.MuiPopover-root.MuiModal-root:not(.MuiMenu-root) {
  z-index: 1314 !important;
}

.card-create-item {
  margin-bottom: 120px !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-no-bg-header {
  background-color: var(--approx-white);
  border: 0;
  padding: 0;
}
.border-0 {
  border: 0 !important;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-initial {
  overflow: initial !important;
}
.mbottom-100 {
  margin-bottom: 100px !important;
}

.kebabMenu .dropdown-item:hover svg path:last-child {
  fill: var(--cerulean-blue);
}
.input-disabled {
  background-color: var(--warm-white) !important;
}
.sub-label {
  font-size: 14px;
  color: var(--battleship-grey);
  font-style: italic;
}
.card-org-hierarchy .action svg {
  margin-right: 8px !important;
}
.mbottom-100 {
  margin-bottom: 100%;
}
.text-align-right {
  text-align: right !important;
}
.text-align-center {
  text-align: center !important;
}
.text-align-left {
  text-align: left !important;
}
.clear-both {
  clear: both !important;
}
td.column-navigation {
  cursor: pointer !important;
}
tr.table-navigation:hover td.column-navigation,
td.column-navigation:hover {
  color: var(--cerulean-blue) !important;
}
.no-click-action,
.no-click-action td {
  cursor: default;
  pointer-events: none !important;
}
.radio-button-wrap .radio-button-group label {
  margin-right: 12px;
}
.radio-button-wrap .radio-button-group label:last-child {
  margin-right: 0;
}
.radio-button-wrap > label {
  margin-bottom: 22px;
  margin-right: 0;
}
.react-multi-email.email-error {
  border: solid 1px var(--red);
}
.react-multi-email.email-error,
.react-multi-email.email-error
  input[type="text"]:not(.react-multi-email.email-error.focused
    input[type="text"]) {
  background-color: var(--rose-white);
}
.mw-none {
  max-width: none !important;
}
.w-220 {
  width: 220px;
}
label {
  width: fit-content;
}
.mw-325 {
  max-width: calc(325px + 2rem) !important;
  width: 100%;
}
.mw-400 {
  max-width: calc(400px + 2rem) !important;
  width: 100%;
}
.date-selector-wrapper {
  clear: both;
}
.table-section table.report-tags-table {
  min-width: 700px !important;
}
.table-section table.report-tags-table tr th:first-child,
.table-section table.report-tags-table tr td:first-child {
  padding-left: 24px !important;
}
.table-section table.report-tags-table tr th:last-child,
.table-section table.report-tags-table tr td:last-child {
  padding-right: 24px !important;
}
.table-section table.report-tags-table tr td:nth-child(2),
.table-section table.report-tags-table tr td:last-child {
  white-space: nowrap;
}
.reportingtag-jv .table-section .table-wrap {
  overflow: hidden;
  overflow-x: auto;
  border: solid 1px var(--warm-white);
  border-radius: 6px;
}
.table-section table.report-tags-table tr:last-child td {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: var(--charcoal-grey);
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.docked-footer-page .form-button-wrapper.docked-button-container {
  position: fixed;
  width: calc(100% - 96px) !important;
  padding-left: 64px !important;
  right: 0 !important;
  bottom: 0 !important;
  -webkit-box-shadow: 0 8px 20px 0 var(--black-shadow3);
  box-shadow: 0 8px 20px 0 var(--black-shadow3);
  border-top: solid 1px var(--warm-white);
  background-color: var(--white-fill) !important;
  opacity: 1 !important;
  z-index: 99;
  padding-top: 16px !important;
  padding-bottom: 20px !important;
  transition: 0.5s;
}
.docked-footer-page
  .form-button-wrapper.docked-button-container.customer-form-section-disable:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--white-fill);
  opacity: 0.5;
  z-index: 1;
}
.expanded-submenu
  .docked-footer-page
  .form-button-wrapper.docked-button-container {
  /* width: calc(100% - 226px) !important;   */
  width: calc(100% - 236px) !important;
}
.docked-footer-page
  .form-button-wrapper.docked-button-container
  .dropdown-menu {
  position: absolute !important;
  inset: auto auto 0px 0px !important;
  margin: 0px !important;
  transform: translate3d(8px, -47px, 0px) !important;
}
.docked-footer-page
  .form-button-wrapper.docked-button-container
  .dropdown-menu {
  position: absolute !important;
  inset: auto auto 0px 0px !important;
  margin: 0px !important;
  transform: translate3d(8px, -47px, 0px) !important;
}
.expanded-submenu
  .docked-footer-page
  .form-button-wrapper.docked-button-container {
  /* width: calc(100% - 226px) !important;   */
  width: calc(100% - 236px) !important;
}
.report-tags-table thead th {
  text-transform: uppercase !important;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: var(--battleship-grey) !important;
}
.primary-btn {
  min-width: 80px;
  padding: 11px 24px;
  border-radius: 6px;
  background-color: var(--booger);
  color: var(--white-fill);
  font-size: 15px;
  font-weight: bold;
}
.primary-btn:hover {
  background-color: var(--booger);
  color: var(--white-fill);
}
.MuiPickersPopper-root {
  z-index: 1800 !important;
}
.text-nowrap {
  white-space: nowrap;
}
.valign-top {
  vertical-align: top !important;
}
.valign-middle {
  vertical-align: middle !important;
}
.valign-bottom {
  vertical-align: bottom !important;
}
.no-border {
  border: 0;
  border-radius: 0;
}
/** Media Queries **/
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}

@media (min-width: 768px) and (max-width: 991px) {
  .main {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sys-transact-num {
    font-size: 13px;
  }
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container
    .form-btn-container {
    flex-wrap: wrap;
  }
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container {
    padding-bottom: 10px;
  }
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container
    .form-btn-container
    > button,
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container
    .form-btn-container
    .btn-group
    > button,
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container
    .form-btn-container
    > a {
    white-space: nowrap;
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main > .main-card {
    margin-bottom: 100px;
  }
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container {
    padding-left: 50px !important;
  }
}
@media (min-width: 768px) and (max-width: 850px) {
  .expanded-submenu .add-module-item-form {
  }
  .expanded-submenu
    .docked-footer-page
    .form-button-wrapper.docked-button-container
    .delete-item-button {
    margin-left: 0 !important;
    float: left;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
@media (max-width: 767px) {
  .main {
    padding-left: 0;
    padding-right: 0;
  }
  .main > .main-card {
    padding-left: 14px;
    padding-right: 14px;
    margin-top: 25px;
  }
  .card-header h1 {
    margin-top: 0 !important;
    font-size: 21px;
  }
}
@media (max-width: 360px) {
}
